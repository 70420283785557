/** Copyright ACCEL 2022 */
/**
  Collection of useful funtions
 */


/**
 * Returns true if the id exists and doesn't starts with a - sign.
 * Most of our ids are numeric, but some are strings (uids).
 * When adding rows we use a minus sign to still give the row a unique id
 * but also differentiate.
 * @param {*} id Usually an int, but can be a string on some objects
 * @returns True if the id exists and doesn't starts with a - sign
 */
export default function IsExistingRecord(id) {
  if (id === undefined) {
    return false;
  }
  return !(id.toString().startsWith('-'));
}
