/**
 Copyright 2021 ACCEL Compliance

 * Takes the result of saving from the api and updates fields on the item that was updated.
 * Fields set are:
 * id - only when the current id is < 0, taken from the save result
 * createdby - only when the current id is < 0, taken from the user's login
 * createddate - only when the current id is < 0, right now
 * lastupdatedby - taken from the user's login
 * lastedatedate - now
 * @param {*} item
 */

import moment from 'moment';

export default function updateFieldsPostSave(theItem, result) {
  const item = theItem;
  const userName = sessionStorage.name;
  const now = moment().format('YYYY-MM-DD H:mm:ss');

  if (item.id < 0) {
    item.id = result.data;
    item.createdby = userName;
    item.createddate = now;
  }

  item.lastupdatedby = userName;
  item.lastupdateddate = now;
}
