<!-- Copyright ACCEL 2019 -->
<!--
Reusable table component for showing reports.  Can be customized with
properties and also slots for rendering details.<template>

Uses bootstrap-vue's Table component but changes the following property defaults:

show-empty - true
bordered - true
outlined - true
small - true
striped - true
sticky-header - 600px
no-border-collapse - true
head-variant - light
hover - true
selectable - false
no-select-on-click - true
select-mode - range

-->

<template>
  <b-container fluid class="px-2">
    <b-row v-if="!hideTitleRow" align-v="center" class="mb-3">
      <b-col align-self="center">

        <b-container fluid>
          <b-row align-v="center" align-h="between">
            <b-col class="text-left ml-n3">
              <h5 v-if="titleValue">{{titleValue}}</h5>
            </b-col>

            <!-- optionally allow users to put extra content to the right side of title -->
            <b-col class="text-right mr-n3">
              <slot name="right-of-title"></slot>
            </b-col>
          </b-row>
        </b-container>
      </b-col>

    </b-row>
    <b-row>
      <b-col>
        <report-table-header
          v-if="!noHeader"
          ref="reportTableHeader"
          :reportId="reportId"
          :title="titleValue"
          :headers="fields"
          :rows="rowsByDate"
          :table="$refs[id]"
          :filter-objects="filterObjects"
          :filtered-rows="filteredRows"
          :selected-rows="selectedRows"
          :hide-column-selector="hideColumnSelector"
          :hide-export="hideExport"
          @show-hide-column="showHideColumn($event)"
          @filter-removed="$emit('filter-removed', $event)"
          @filter-change-value="$emit('filter-change-value', $event)"
        >


          <!-- optionally allow users to put extra content before the export button -->
          <template v-slot:before-export>
              <slot name="before-export-content"></slot>

            <b-form-select
              v-if="dateFilterField"
              :options="dateFilterOptions"
              class="h-auto"
              v-model="dateFilterValue"
              size="sm"
              @input="setDateFilterMoments()"
              :autofocus="false"
              v-b-tooltip.hover :title="dateFilterTooltip"
              >
            </b-form-select>
          </template>


        </report-table-header>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <!--
        Main table element
        Note that not all properties on bootstrap table are currently configured
        to be customized.  If customization is needed, change the property to read
        from a field instead of being hard-coded here.  Provide a default value
        in this case so it doesn't break other pages currently using this component.
        -->
        <b-table
          :id="id"
          :ref="id"
          :show-empty="showEmpty"
          :bordered="bordered"
          :outlined="outlined"
          :small="small"
          :striped="striped"
          :sticky-header="stickyHeader"
          :no-border-collapse="noBorderCollapse"
          :head-variant="headVariant"
          :hover="hover"
          :selectable="selectable"
          @row-selected="onRowSelected"
          :no-select-on-click="noSelectOnClick"
          :select-mode="selectMode"
          :items="rowsByDate"
          :fields="fields"
          :busy="isBusy"
          :per-page="noPaging ? 0 : perPageData"
          :current-page="currentPage"
          :filter="filterObjects"
          :filter-function="filterRow"
          @filtered="onFiltered"
          @sort-changed="clearSelected"
          :empty-filtered-text="emptyFilteredText"
        >
          <!-- busy renderer -->
          <template v-slot:table-busy>
            <div class="text-center text-info my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>&nbsp;Loading...</strong>
            </div>
          </template>

          <!-- A custom formatted header cell for header 'selected' -->
          <template v-slot:head(selected)>
            <b-form-checkbox
              class="align-middle"
              @change="toggleAllRowsSelected()"
              v-model="allRowsSelected"
              switch
            />
          </template>

          <!-- Scoped slot for select state -->
          <template v-slot:cell(selected)="row">
            <b-form-checkbox
              v-if="rowIsSelectable(row.item)"
              :checked="row.rowSelected"
              class="align-middle h-100"
              @change="toggleRowSelect($event, row.index)"
              switch
            />
          </template>


          <!-- optionally allow users to put a header content on the show details header -->
          <template v-slot:head(show_details)>
            <div class="d-inline-flex justify-content-end ml-3">
              <slot name="show-details-header"
                v-bind:selectedRows="selectedRows"
              ></slot>
            </div>
          </template>


          <!-- optionally allow users to put a content on the show details cell per row -->
          <template v-slot:cell(show_details)="row">
            <div class="d-inline-flex justify-content-end ml-3">
              <slot name="show-details-cell"
                v-bind:row="row"
              ></slot>
            </div>
          </template>

          <!-- scoped slot for show details button -->
          <template v-slot:row-details="row">
              <slot name="show-details-row"
                v-bind:row="row"
              >default text</slot>
          </template>

          <!-- Custom-formatted cell for id cells - don't show negative numbers -->
          <template v-slot:cell(id)="row">
            <span v-if="row.item.id &&  IsExistingRecord(row.item.id)">
              {{ row.item.id}}
            </span>
          </template>


          <!-- Custom-formatted cell for document_id cells -->
          <template v-slot:cell(document_id)="row">
            <a
              v-if="row.item.document_id"
              href="#"
              @click.prevent="downloadDocument(row.item)"
              download
            >
              <font-awesome-icon :icon="['fas', 'file-download']" size="lg"/>
            </a>
          </template>


          <!-- 5 spaces available for custom rendering of cells
            See documentation in the properties section for usage
          -->
          <template
            v-if="customSlotName1"
            v-slot:[`cell(${customSlotName1})`]="row">
            <slot :name="`custom-cell(${customSlotName1})`" v-bind:row="row"/>
          </template>
          <template
            v-if="customSlotName2"
            v-slot:[`cell(${customSlotName2})`]="row">
            <slot :name="`custom-cell(${customSlotName2})`" v-bind:row="row"/>
          </template>
          <template
            v-if="customSlotName3"
            v-slot:[`cell(${customSlotName3})`]="row">
            <slot :name="`custom-cell(${customSlotName3})`" v-bind:row="row"/>
          </template>
          <template
            v-if="customSlotName4"
            v-slot:[`cell(${customSlotName4})`]="row">
            <slot :name="`custom-cell(${customSlotName4})`" v-bind:row="row"/>
          </template>
          <template
            v-if="customSlotName5"
            v-slot:[`cell(${customSlotName5})`]="row">
            <slot :name="`custom-cell(${customSlotName5})`" v-bind:row="row"/>
          </template>
          <template
            v-if="customSlotName6"
            v-slot:[`cell(${customSlotName6})`]="row">
            <slot :name="`custom-cell(${customSlotName6})`" v-bind:row="row"/>
          </template>
          <template
            v-if="customSlotName7"
            v-slot:[`cell(${customSlotName7})`]="row">
            <slot :name="`custom-cell(${customSlotName7})`" v-bind:row="row"/>
          </template>
          <template
            v-if="customSlotName8"
            v-slot:[`cell(${customSlotName8})`]="row">
            <slot :name="`custom-cell(${customSlotName8})`" v-bind:row="row"/>
          </template>
          <template
            v-if="customSlotName9"
            v-slot:[`cell(${customSlotName9})`]="row">
            <slot :name="`custom-cell(${customSlotName9})`" v-bind:row="row"/>
          </template>
          <template
            v-if="customSlotName10"
            v-slot:[`cell(${customSlotName10})`]="row">
            <slot :name="`custom-cell(${customSlotName10})`" v-bind:row="row"/>
          </template>

        </b-table>
      </b-col>
    </b-row>
    <b-row v-if="!noPaging" >
      <!-- for some reason this gets chopped off with the footer.
      Force it to have a recognizeable height -->
      <b-col style="height: 100px;">
        <report-paging
          :initial-page="currentPage"
          :row-count="rowCount"
          :initial-per-page="perPageData"
          @change-page="currentPage = $event"
          @change-perpage="perPageData = $event"
        >

          <!-- optionally allow users to put extra content before the export button -->
          <template v-slot:after-paging>
              <slot name="after-paging-content"></slot>
          </template>

        </report-paging>
      </b-col>
    </b-row>
  </b-container>
</template>


<script>
import moment from 'moment';
import axiosClient from '../scripts/axiosclient';
import FilterItem from '../scripts/filterItem';
import { GetLocalStorageJSONWithDefault, SetLocalStorageJSON } from '../scripts/localstoragehelper';
import IsExistingRecord from '../scripts/utils';
import ReportPaging from './ReportPaging.vue';
import ReportTableHeader from './ReportTableHeader.vue';


export default {
  name: 'ReportTable',
  components: {
    ReportTableHeader,
    ReportPaging,
  },
  props: {
    hideTitleRow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },

    hideColumnSelector: {
      type: Boolean,
      default: false,
    },
    hideExport: {
      type: Boolean,
      default: false,
    },

    noPaging: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
    },
    showEmpty: {
      type: Boolean,
      default: true,
    },
    bordered: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    small: {
      type: Boolean,
      default: true,
    },
    striped: {
      type: Boolean,
      default: true,
    },
    stickyHeader: {
      type: [String, Boolean], // may be Boolean or String
      default: '800px',
    },
    noBorderCollapse: {
      type: Boolean,
      default: true,
    },
    headVariant: {
      type: String,
      default: 'light',
    },
    hover: {
      type: Boolean,
      default: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    noSelectOnClick: {
      type: Boolean,
      default: true,
    },
    selectMode: {
      type: String,
      default: 'range',
    },
    selectRowOverride: {
      type: Function,
      default: null,
    },
    showDetails: {
      type: Boolean,
      default: false,
    },
    perPage: {
      type: Number,
      default: 50,
    },
    reportUrl: {
      type: String,
    },

    /*
      Use date filters to provide the user with a dropdown limiting the date range of
      the data displayed in the table.  Here's how to use the date filter:

      First, define the options that you want to present to the user.
      In the data() section of your component put:

      // Options for date filtering
      dateFilterOptions: [
        { value: '0 d', text: 'Past Due' },
        { value: { min: '0 d', max: '45 d' }, text: '45 Days or Less' },
        { value: { min: '0 d', max: '90 d' }, text: '90 Days or Less' },
        { value: { nullsOnly: true }, text: 'Exempt' },
        { value: 'null', text: 'All' },
      ],

      The date filter options is an array of values that will go into the boostrap-vue
      form-select (see https://bootstrap-vue.org/docs/components/form-select)
      The values should either be strings representing an upper bound in time
      (see https://momentjs.com/docs/#/manipulating/ for the appropriate shorthand
      for time periods), or an object with properties 'min' and 'max' to provide
      both an upper and lower bound on time.  A null value can be set to indicate that
      the date should not be filtered. The text attribute is the label.


      Next, when creating the ReportTable component, set the following properties:

      date-filter-tooltip="Select the due date timeframe"
      date-filter-field="due_date"
      date-filter-default-value="0 d"
      :date-filter-options="dateFilterOptions"

      1. date-filter-tooltip - the tooltip text on hover
      2. date-filter-field - the name of the api column that we should look at for
              date filtering.  Must be one of the values coming back in the "fields"
              section of the report
      3. date-filter-default-value - The default value when the screen loads.  This must
              match one of the options defined in the data section
      4. :date-filter-options - Points to the data variable that holds the options
    */

    dateFilterField: {
      type: String,
    },
    dateFilterOptions: {
      type: Array,
    },
    dateFilterTooltip: {
      type: String,
    },
    dateFilterDefaultValue: {
      type: [String, Object],
    },

    /*
      5 custom slots for rendering columns in the report

      To use these, set the component property
      "customSlotNameX" (where X is 1-5) value to the api name
      of the column.
      Then create a template section like:

      <template v-slot:custom-cell(my_col)="{row}">
        custom render the column
      </template>

      where "my_col" is the same value set in the customSlotNameX property.
      The "row" binding gives access to the bootstrap row object being
      rendered.  To access the data on the row, use row.item.
    */
    customSlotName1: {
      type: String,
      default: null,
    },
    customSlotName2: {
      type: String,
      default: null,
    },
    customSlotName3: {
      type: String,
      default: null,
    },
    customSlotName4: {
      type: String,
      default: null,
    },
    customSlotName5: {
      type: String,
      default: null,
    },
    customSlotName6: {
      type: String,
      default: null,
    },
    customSlotName7: {
      type: String,
      default: null,
    },
    customSlotName8: {
      type: String,
      default: null,
    },
    customSlotName9: {
      type: String,
      default: null,
    },
    customSlotName10: {
      type: String,
      default: null,
    },

    /**
     * Filter override function.  If provided, this will be called when
     * filtering a row and if the function returns true then the row
     * will be included in the display despite whatever filters are in place.
     * This can be used to ensure that newly created rows aren't filtered.
     */
    filterOverride: {
      type: Function,
      default: null,
    },

    /**
     * Filter addition function.  If provided, this will be called when
     * filtering a row and added to the list of filters already in placed.
     * This can be used to put additional hidden filteer
     */
    filterAddition: {
      type: Function,
      default: null,
    },

    emptyFilteredText: {
      type: String,
      default: 'There are no records matching your request',
    },

    /**
     * When doing a "manual" report (items are not retrieved from the server)
     * These properties can be set to provide the headers and initial values
     * of the rows.
     */
    manualDefaultFilters: {
      type: Array,
      default() { return []; },
    },
    manualFields: {
      type: Array,
      default() { return []; },
    },
    manualRows: {
      type: Array,
      default() { return []; },
    },
  },

  data() {
    return {
      rows: [],
      fields: [],
      filteredRows: [],
      selectedRows: [],
      perPageData: 50,
      currentPage: 1,
      allRowsSelected: false,
      isBusy: true,
      filterObjects: [],
      togglingDetails: false, // needed to stop filtering when showing details
      modalNoCloseOnBackdrop: true,
      dateFilterValue: this.dateFilterDefaultValue,
      dateFilterNullsOnly: false,
      dateFilterMomentMin: null,
      dateFilterMomentMax: null,
      reportTitle: null,
    };
  },

  computed: {

    /**
     * Returns either the fixed title property or the report title.
     */
    titleValue() {
      if (this.title) return this.title;
      return this.reportTitle;
    },

    /**
     * Create a computed set of rows when the when the filter date changes.
     * This is faster than doing a regular filter every time
     */
    rowsByDate() {
      if (this.dateFilterMomentMin || this.dateFilterMomentMax || this.dateFilterNullsOnly) {
        const temp = this.rows.filter((row) => {
          // Check for filter override function
          if (this.filterOverride && this.filterOverride(row)) {
            return true;
          }

          let isMatch = true; // Assume it matches.  Set to false if no match
          const dateVal = row[this.dateFilterField];

          // If nulls only, only use the row if there is no date
          if (this.dateFilterNullsOnly) {
            isMatch = (!dateVal) || (dateVal === '');
          } else {
            // otherwise look at the date
            const rowDate = moment(dateVal);
            if (this.dateFilterMomentMin) {
              isMatch = isMatch && rowDate.isSameOrAfter(this.dateFilterMomentMin, 'day');
            }
            if (this.dateFilterMomentMax) {
              isMatch = isMatch && rowDate.isSameOrBefore(this.dateFilterMomentMax, 'day');
            }
          }
          return isMatch;
        });
        return temp;
      }
      return this.rows;
    },

    /**
     * Get row count either from the number of filtered rows (if there are filters)
     * or the number of rows matching the date
     */
    rowCount() {
      if (this.filterObjects && this.filterObjects.length > 0) { return this.filteredRows.length; }
      return this.rowsByDate.length;
    },

    /**
     * Gets the "id" of the report from the url.  Specificially, this is the value
     * of the "view" parameter from the url.
     * Returns empty string if there is no report url or the value is "manual" or "Null"
     */
    reportId() {
      if (!this.reportUrl) {
        return '';
      }

      const url = new URL(`http://server/${this.reportUrl}`);
      const viewName = url.searchParams.get('view');
      if (!viewName || viewName === 'null' || viewName === 'manual') return '';
      return viewName;
    },
  },

  methods: {
    IsExistingRecord,

    /**
     * Method to determine whether a row is selectable.  Normally this will
     * just return true.  If the user of this component provides a "selectRowOverride"
     * implementation, it will return the result of that function.
     * @param {*} row
     */
    rowIsSelectable(rowItem) {
      if (this.selectRowOverride != null) {
        return this.selectRowOverride(rowItem);
      }
      return true;
    },

    /**
     * Looks at the date filter value and sets the future "moment"
     * and past "moment" (as applicable) for comparing when filtering.
     * Do this so that when filtering we don't have to rebuild the date for every row.
     *
     * The value is expected to either be a string representing the "max" moment
     * or an object with min and max moment attributes (either or both),
     * or an object with nullsonly attribute set to true,
     * or a null string if there should be no date filtering (All)
     */
    setDateFilterMoments() {
      // Clear the existing selection
      this.dateFilterMomentMin = null;
      this.dateFilterMomentMax = null;
      this.dateFilterNullsOnly = false;

      if (this.dateFilterValue) {
        // See if we are doing nullsonly
        const { nullsOnly } = this.dateFilterValue;
        if (nullsOnly) {
          this.dateFilterNullsOnly = true;
        } else {
          // Try to get a min and a max from an object version
          const minVal = this.dateFilterValue.min;
          let maxVal = this.dateFilterValue.max;

          // If neither was defined then the value should be a string for the max
          if ((!maxVal) && (!minVal)) {
            maxVal = this.dateFilterValue;
          }

          if (minVal) {
            const parts = minVal.split(' ');
            this.dateFilterMomentMin = moment().add(Number(parts[0]), parts[1]);
          }
          if (maxVal) {
            const parts = maxVal.split(' ');
            this.dateFilterMomentMax = moment().add(Number(parts[0]), parts[1]);
          }
        }
      }
      this.clearSelected();
    },

    /**
     * Filter for each row.  Check the filter definitions to find columns
     * that have a filter provided, and if so if the value in the row matches.
     *
     * In addition to passing this through the normal filters, we must also
     * check to see if it passes the date range selected.
     *
     * This is very unusual in Vue, to perform a callback.  Normally this would
     * be done by emitting an event, but in this case we need to look at the
     * value returned and see if it's true
     */
    filterRow(row) {
      // Check for filter override function
      // Stop if there is a match
      if (this.filterOverride && this.filterOverride(row)) {
        return true;
      }

      // Check for additional filter logic and combine it with the
      // normal filtering
      let filterResult = true;
      if (this.filterAddition) {
        filterResult = this.filterAddition(row);
      }
      return filterResult && this.$refs.reportTableHeader.filterRow(row);
    },

    /**
     * Get the report data from the given url
     */
    getReport() {
      this.isBusy = true;
      const path = this.reportUrl;
      if (path == null || path.endsWith('view=null')) return; // Component not ready yet

      // If the view is "manual" then the component using this must
      // provide all the features of the report in the callbacks.
      // This is useful to have the UI build a report table
      if (path.endsWith('view=manual')) {
        this.populateTable({
          defaultFilters: this.manualDefaultFilters,
          fields: this.manualFields,
          rows: this.manualRows,
        });
        this.$emit('report-loaded');
      } else {
        let json;
        axiosClient
          .get(path)
          .then((res) => {
            json = res.data;
            this.populateTable(json);
            this.$emit('report-loaded');
          })
          .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
          });
      }
    },
    /**
     * Populates the table based on the json received in the report
     */
    populateTable(json) {
      this.$emit('report-retrieved', json);

      // If we don't have a title already, get it from the report
      if (!this.title) {
        this.reportTitle = json.title;
      }

      this.rows = json.rows;
      this.filteredRows = this.rowsByDate;
      // debugger

      const thClassBaseStr = 'small position-sticky text-center align-middle font-weight-bold';
      let storedHeaders = {};
      if (this.reportId !== '') {
        storedHeaders = GetLocalStorageJSONWithDefault('reportHeaders', {})[this.reportId];
      }

      // Get the headers from the response, but convert the type
      // to an alignment
      this.fields = json.fields
        .filter(
          field => !field.hidden,
        )
        .map((field) => {
          let tdClassStr = 'small ';
          let sortable = true;
          let filterable = true;
          switch (field.datatype) {
            case 'real':
            case 'double':
            case 'smallint':
            case 'integer':
            case 'bigint':
            case 'numeric':
            case 'float8':
              tdClassStr += 'text-right ';
              break;
            default:
              tdClassStr += 'text-left ';
          }

          // Columns with no label should not be filterable
          if (!field.label || field.label.trim().length === 0) {
            filterable = false;
          } else if (field.label.endsWith(' Date') || field.label === 'DoB') {
            // Date fields should not wrap
            tdClassStr += 'text-nowrap ';
          } else if (field.key === 'document_id') {
            // Document_id fields should be centered (download icon)
            // and not sortable
            sortable = false;
            filterable = false;
            tdClassStr = 'text-center align-middle text-nowrap';
          }

          // For the displayed flag, see if we have a setting in local
          // storage for this report, and if so use that value.  Otherwise
          // Use the value from the report definition.  Do not store the
          // user's choice in local storage until they actively show/hide a column
          let { displayed } = field;
          if (storedHeaders !== undefined) { // may be no stored headers for this report yet
            const storedValue = storedHeaders[field.key];
            if (storedHeaders && storedValue !== undefined) {
              displayed = storedValue;
            }
          }

          let tdClassDisplayedStr = tdClassStr;
          let thClassDisplayedStr = thClassBaseStr;
          if (!displayed) {
            tdClassDisplayedStr += ' d-none';
            thClassDisplayedStr += ' d-none';
          }

          const newHeader = {
            tdClass: tdClassDisplayedStr,
            tdClassReset: tdClassStr, // used when we show/hide to restore
            thClass: thClassDisplayedStr,
            thClassReset: thClassBaseStr, // used when we show/hide to restore
            sortable,
            filterable,
            key: field.key,
            label: field.label,
            displayed,
            isRowHeader: true,
          };

          return newHeader;
        });

      // Add extra columns for selecting and showing details
      if (this.selectable) {
        this.fields.unshift({
          key: 'selected',
          label: '',
          sortable: false,
          thClass: `${thClassBaseStr} align-middle text-nowrap`,
          tdClass: 'text-center align-middle text-nowrap',
        });
      }

      if (this.showDetails) {
        this.fields.push({
          key: 'show_details',
          label: '',
          sortable: false,
          thClass: `${thClassBaseStr} align-middle text-nowrap`,
          tdClass: 'text-center align-middle text-nowrap',
        });
      }

      this.isBusy = false;

      // If we have stored filters for this report, use those
      // Otherwise, use the default filters from the report
      this.filterObjects.length = 0; // reset the filters

      let storedFilters = {};
      if (this.reportId !== '') {
        storedFilters = GetLocalStorageJSONWithDefault('reportFilters', {})[this.reportId];
      }
      if (storedFilters && storedFilters.length > 0) {
        storedFilters.forEach((fo) => {
          const f = new FilterItem();
          f.fieldName = fo.fieldName;
          f.valueList = fo.valueList;
          this.filterObjects.push(f);
        });
      } else if (json.defaultFilters) {
        json.defaultFilters.forEach((columnKey) => {
          const f = new FilterItem();
          f.fieldName = columnKey;
          this.filterObjects.push(f);
        });
      }
    },

    /**
     * Adds a value to a filter.  If the filter is not already shown
     * and "createIfNeeded" is true, then a new filter item is created
     */
    addFilter(columnName, value, createIfNeeded) {
      let filterItem = this.filterObjects.find(fi => fi.fieldName === columnName);
      if (!filterItem && createIfNeeded) {
        filterItem = new FilterItem();
        filterItem.autoAdded = true;
        filterItem.fieldName = columnName;
        this.filterObjects.push(filterItem);
      }
      if (filterItem) {
        filterItem.addValue(value);
      }
    },

    /**
     * Method called whenever the selection changes.  Update the
     * "selectedRows" field with the new selections
     */
    onRowSelected(items) {
      this.selectedRows = items;

      // If we have a row select override function, then for each item
      // in the list, check to see if it selected and should not be.  If
      // so do an unselect.
      if (this.selectRowOverride != null) {
        const table = this.$refs[this.id];
        items.forEach((item, index) => {
          if (table.isRowSelected(index) && !(this.selectRowOverride(item))) {
            table.unselectRow(index);
            // this function will be called again after unselect
          }
        });
      }
    },

    /**
     * Selects all rows
     */
    selectAllRows() {
      this.$refs[this.id].selectAllRows();
    },

    /**
     * Unselects all rows and makes sure that the select all is off
     */
    clearSelected() {
      if (this.$refs[this.id]) {
        this.$refs[this.id].clearSelected();
        this.allRowsSelected = false;
      }
    },

    /**
     * Select or unselects all rows
     */
    toggleAllRowsSelected() {
      if (this.allRowsSelected) {
        this.selectAllRows();
      } else {
        this.clearSelected();
      }
    },

    /**
     * Selects or unselects a particular row, based on whether the
     * event exists (checkbox checked) or not (checkbox unchecked)
     */
    toggleRowSelect(event, index) {
      if (event) {
        this.$refs[this.id].selectRow(index);
      } else {
        this.$refs[this.id].unselectRow(index);
      }
    },


    /**
     * Issue when showing details, the onFiltered gets called and the
     * row number resets.  Prevent that by wrapping the toggleDetails
     */
    toggleRowDetails(row) {
      this.togglingDetails = true;
      row.toggleDetails();
    },

    /**
     * Marks a particular column as shown or hidden specifically rather than toggling.
     */
    setShowHideColumn(key, show) {
      this.fields.forEach((field) => {
        if (field.key === key) {
          if (field.displayed !== show) {
            this.showHideColumnInternal(field, true);
          }
        }
      });
    },

    /**
     * Shows or hides a particular column, based on the column key.
     */
    showHideColumn(key) {
      this.fields.forEach((field) => {
        if (field.key === key) {
          this.showHideColumnInternal(field, false);

          // Store the user's preference
          if (this.reportId !== '') {
            const storedHeaders = GetLocalStorageJSONWithDefault('reportHeaders', {});
            let reportHeaders = storedHeaders[this.reportId];
            if (!reportHeaders) {
              reportHeaders = {};
              storedHeaders[this.reportId] = reportHeaders;
            }
            reportHeaders[key] = field.displayed;
            SetLocalStorageJSON('reportHeaders', storedHeaders);
          }
        }
      });
    },


    /**
     * Internal implementation of show/hide that takes the field
     * that's already been located
     * If toggleDisplay is true then the .displayed value will be flipped
     * on the field.  This should be set to true when programmatically changing
     * the show/hide instead of the user interaction
     */
    showHideColumnInternal(theField, toggleDisplay) {
      const field = theField;
      if (toggleDisplay) {
        field.displayed = !field.displayed;
      }

      if (field.displayed) {
        field.thClass = field.thClassReset;
        field.tdClass = field.tdClassReset;
      } else {
        field.thClass = `${field.thClassReset} d-none`;
        field.tdClass = `${field.tdClassReset} d-none`;
      }
    },

    /**
     * Provides a way for users of this component to temporarily add new data to the
     * table without going back to the server.  The caller must be fully aware of the
     * exected structure of the row data and match all the column names
     *
     * Note that a browser refresh will discard these rows but they should come from the
     * db if they were added correctly after making an api call
     */
    addRow(newRow) {
      this.rows.unshift(newRow);
      this.$refs[this.id].refresh(); // refresh table to get the sort
    },


    /**
     * Removes all the rows that have one of the matching values for the given
     * field.  Note that a browser refresh will make the rows
     * appear again unless another action has been taken to remove them from the data.
     */
    removeRows(fieldName, fieldValues) {
      // remove the selected rows matching on ids
      fieldValues.forEach((val) => {
        for (let index = 0; index < this.rows.length; index += 1) {
          if (this.rows[index][fieldName] === val) {
            this.rows.splice(index, 1);
            break;
          }
        }
      });
    },

    /**
     * Called when the filter changes
     */
    onFiltered(filteredItems) {
      if (!this.togglingDetails) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.currentPage = 1;
        this.filteredRows = filteredItems;
      }
      this.togglingDetails = false;
      this.clearSelected();
    },


    /**
     * Refresh the report with new information
     */
    refreshReport() {
      this.setDateFilterMoments();
      this.getReport();
    },


    /**
     * Download a document by getting the shareable url and
     * creating a link on the fly to download
     */
    downloadDocument(rowItemInput) {
      const rowItem = rowItemInput;
      const docId = rowItem.document_id;
      const path = `services/file/${docId}`;

      axiosClient
        .get(path, { responseType: 'blob' })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;

          // Get the file name from the special x-filename header
          const fileName = res.headers['x-filename'];
          link.setAttribute('download', fileName);
          // document.body.appendChild(link);
          link.click();

          // If there is a processed date and processed by, and
          // the user is an admin, set those fields in the UI so
          // they appear before the next refresh
          if (sessionStorage.isAnalyst
            && 'processed_date' in rowItem
            && 'processed_by_name' in rowItem
            && !rowItem.processed_date) {
            const nowString = moment().format('YYYY-MM-DD');
            rowItem.processed_date = nowString;
            rowItem.processed_by_name = sessionStorage.name;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
  },
  created() {
    this.refreshReport();
  },
  watch: {
    reportUrl() {
      this.refreshReport();
    },

    /**
     * If the default date filter value changes, update the selection
     */
    dateFilterDefaultValue() {
      this.dateFilterValue = this.dateFilterDefaultValue;
    },
  },
};
</script>
