/**
Copyright 2020 ACCEL Compliance

Mix-in for calling a chain of handlers when a report is retrieved.
Different mixins may want to perform various actions when the report
is loaded, so this provides a way to register calls and to
call a single method that chains them together.

Users should call handleReportRetrievedChain() as the event handler
for the report table
      @report-retrieved="handleReportRetrievedChain"

But must register handlers on component creation
  this.addReportRetrievedHandler(this.some_mixin_handler_function)

*/


export default {

  data() {
    return {
      // The list of handlers
      handlerChain: [],

    };
  },

  methods: {

    /**
     * Loops through all the handlers registered and calls them with the
     * json received on report retrieved event
     */
    handleReportRetrievedChain(json) {
      this.handlerChain.forEach((handler) => {
        handler(json);
      });
    },

    /**
     * Adds a new handler to the chain
     */
    addReportRetrievedHandler(handler) {
      this.handlerChain.push(handler);
    },
  },

};
