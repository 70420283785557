
/** Copyright ACCEL 2019 */
/**
  Javascript library for exporting table to csv or pdf
*/
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

/**
 * Checks to see if the given object is a string.  Returns true if so
 * @param {*} obj The object to check whether it's a string
 */
function isString(obj) {
  return (Object.prototype.toString.call(obj) === '[object String]');
}

/**
 * Takes a string input for putting into a csv file and
 * prefixes it with a double quote, postfixes it with a
 * double quote, and escapes any double quotes in the actual
 * value.
 *
 * i.e. Value with"a quote
 * would return: Value with""a quote
 *
 * which would then be put in the csv row:
 * "col1","Value with""a quote","col3"
 *
 * @param {String} input
 * @returns {String} escaped value
 */

function prepareForCsv(input) {
  if (!input) return '""';
  if (isString(input)) {
    return `"${input.replace(/"/g, '""')}"`;
  }
  return input;
}

/**
 * Exports data as csv given a JSON array of header objects,
 * the key to use for the header label, the key to use for the header "key"
 * and the JSON array of rows to export.  Each element in the rows array
 * should be a JSON object and only the keys that match the header key will
 * be exported.
 * The CSV file will be comma-separated with quotes around each of the fields, with
 * quotes being escaped.
 * @param {Array} headers The array of JSON objects for the headers.
 * @param {String} headerLabelField The name of the field on the JSON header object
 *  to use to pull the header name from
 * @param {String} headerKeyField
 * @param {Array} rows
 */
export function exportCsv(name, headers, headerLabelField, headerKeyField, rows) {
  // First line has special header content.  No newline yet
  const responseContent = 'data:text/csv;charset=utf-8,';

  let csvContent = headers.map(header => prepareForCsv(header[headerLabelField])).join(',');
  csvContent += '\n'; // newline after headers

  // prepare an array of header keys for quicker access
  const headerKeys = headers.map(header => header[headerKeyField]);

  rows.forEach((row) => {
    csvContent += headerKeys.map(key => prepareForCsv(row[key])).join(',');
    csvContent += '\n'; // newline after each row
  });

  const data = responseContent + encodeURIComponent(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', data);
  link.setAttribute('download', `${name}.csv`);
  link.click();
}

/**
 * Exports data as csv given a JSON array of header objects,
 * the key to use for the header label, the key to use for the header "key"
 * and the JSON array of rows to export.  Each element in the rows array
 * should be a JSON object and only the keys that match the header key will
 * be exported.
 * The CSV file will be comma-separated with quotes around each of the fields, with
 * quotes being escaped.
 * @param {Array} headers The array of JSON objects for the headers.
 * @param {String} headerLabelField The name of the field on the JSON header object
 *  to use to pull the header name from
 * @param {String} headerKeyField
 * @param {Array} rows
 */
export function exportPdf(name, headers, headerLabelField, headerKeyField, rows) {
  const pdfHeaders = headers.map(header => header[headerLabelField]);

  // Each row should be an object of name/value pairs
  const pdfRows = rows.map((row) => {
    const pdfRow = headers.map(header => row[header[headerKeyField]])
      .map(val => (val || ''));
    return pdfRow;
  });

  // eslint-disable-next-line new-cap
  const document = new jsPDF({ putOnlyUsedFonts: true, orientation: 'landscape' });
  // doc.table(1, 1, pdfRows, pdfHeaders, { autoSize: true });

  // const doc = new JsPDF();
  document.autoTable({
    head: [pdfHeaders],
    body: pdfRows,


    /**
   * Prevent AutoTable from breaking lines in the middle of words
   * by setting the minWidth to the longest word in the column
   *
   * WARNING: this may cause the table to exceede the allowed width (just like in HTML)
   * and give a "can't fit page" console error, could be improved.
   */
    didParseCell({ doc, cell, column }) {
      if (cell === undefined) {
        return;
      }

      const cellConst = cell;
      const columnConst = column;

      const hasCustomWidth = (typeof cellConst.styles.cellWidth === 'number');

      if (hasCustomWidth || cellConst.raw == null || cellConst.colSpan > 1) {
        return;
      }

      let text;

      if (cellConst.raw instanceof Node) {
        text = cellConst.raw.innerText;
      } else {
        if (typeof cellConst.raw === 'object') {
        // not implemented yet
        // when a cell contains other cells (colSpan)
          return;
        }
        text = `${cell.raw}`;
      }

      // split cell string by spaces
      const words = text.split(/\s+/);

      // calculate longest word width
      const maxWordUnitWidth = words.map(s => Math.floor(doc.getStringUnitWidth(s) * 100) / 100)
        .reduce((a, b) => Math.max(a, b), 0);
      const maxWordWidth = maxWordUnitWidth
        * (cellConst.styles.fontSize / doc.internal.scaleFactor);

      const minWidth = cellConst.padding('horizontal') + maxWordWidth;

      // update minWidth for cell & column

      if (minWidth > cellConst.minWidth) {
        cellConst.minWidth = minWidth;
      }

      if (cellConst.minWidth > cellConst.wrappedWidth) {
        cellConst.wrappedWidth = cellConst.minWidth;
      }

      if (cellConst.minWidth > columnConst.minWidth) {
        columnConst.minWidth = cellConst.minWidth;
      }

      if (columnConst.minWidth > columnConst.wrappedWidth) {
        columnConst.wrappedWidth = columnConst.minWidth;
      }
    },
  });

  document.save(`${name}.pdf`);
}


/**
 * Exports data as csv or pdf given a JSON array of header objects,
 * the key to use for the header label, the key to use for the header "key"
 * and the JSON array of rows to export.  Each element in the rows array
 * should be a JSON object and only the keys that match the header key will
 * be exported.
 * The CSV file will be comma-separated with quotes around each of the fields, with
 * quotes being escaped.
 * @param {String} fileType Must be 'csv' or 'pdf'
 * @param {Array} headers The array of JSON objects for the headers.
 * @param {String} headerLabelField The name of the field on the JSON header object
 *  to use to pull the header name from
 * @param {String} headerKeyField
 * @param {Array} rows
 */
function exportToFile(fileType, name, headers, headerLabelField, headerKeyField, rows) {
  if (fileType === 'csv') exportCsv(name, headers, headerLabelField, headerKeyField, rows);
  else exportPdf(name, headers, headerLabelField, headerKeyField, rows);
}

export default exportToFile;
