<!--
Copyright 2020 ACCEL Compliance

Re-usable component for deleting the selected rows row from a table.

Defines:

 The main component must specify the data field "deleteEndpoint",
 which should look like "services/notification".  The id of the
 item to delete will be appended to this.  This will only be called
 if the id indicates an existing record (does not start with "-"),
 so temporary rows won't make calls to the backend

 The main component may also implement a method named "afterDeleteRows"
 which will be called after successful delete.  This can be used to update
 counters and other things
-->

<template>

  <div class="small text-center align-middle text-nowrap">

    <!-- delete button -->

    <b-button
      v-b-modal.delete-confirmation
      size="sm"
      class="small"
      variant="danger"
      :disabled="selectedRows.length == 0"
      v-b-tooltip.hover.lefttop
      :title="actionVerb + ' selected ' + objectTypePlural"
      >
      <font-awesome-icon :icon="buttonIcon" />
      <span class="ml-1">{{ actionVerb }}</span>
    </b-button>


    <!-- modal dialog to confirm deleting -->
    <b-modal
      id="delete-confirmation"
      centered
      no-close-on-backdrop
      :title="'Confirm ' + capitalizedObject + ' ' + actionVerb"
      header-bg-variant="secondary"
      header-text-variant="light"
      button-size="sm"
      @ok="deleteRows(selectedRows)"
      >
      <p v-if="selectedRows.length == 1" class="text-left mb-1">
        {{ actionVerb }} {{ selectedRows.length }} {{objectType}}?
      </p>
      <p v-else class="text-left mb-1">
        {{ actionVerb }} {{ selectedRows.length }} {{objectTypePlural}}?
      </p>
    </b-modal>
  </div>
</template>

<script>
import axiosClient from '../../scripts/axiosclient';
import IsExistingRecord from '../../scripts/utils';

export default {
  name: 'DeleteRows',

  props: {
    selectedRows: {
      type: Array,
    },
    tableReference: {
      type: Object,
    },
    deleteEndpoint: {
      type: String,
      required: true,
    },
    objectType: {
      type: String,
      required: true,
    },
    objectTypePlural: {
      type: String,
      required: true,
    },
    afterDeleteRows: {
      type: Function,
    },
    afterUndeleteRows: {
      type: Function,
    },
    workingWithDeleted: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    capitalizedObject() {
      const words = this.objectType.split(' ');

      return words.map(word => word[0].toUpperCase() + word.substring(1)).join(' ');
    },

    /**
     * Returns either "Delete" or "Undelete" based on the current state.
     */
    actionVerb() {
      if (this.workingWithDeleted) {
        return 'Undelete';
      }
      return 'Delete';
    },

    /**
     * Returns either ['fas', 'trash'] or ['fas', 'trash-restore'] based on the current state.
     */
    buttonIcon() {
      if (this.workingWithDeleted) {
        return ['fas', 'trash-restore'];
      }
      return ['fas', 'trash'];
    },
  },

  methods: {

    // Import this method to we can properly identify ids
    // that are not numeric
    IsExistingRecord,

    /**
     * Deletes a row
     */
    deleteRow(id) {
      if (this.IsExistingRecord(id)) {
        const path = `${this.deleteEndpoint}/${id}`;
        // const ids = [id];


        axiosClient
          .delete(path)
          // eslint-disable-next-line no-unused-vars
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
          });
      }
    },

    /**
     * Deletes a row
     */
    undeleteRow(id) {
      if (this.IsExistingRecord(id)) {
        const json = {
          deleted: false,
        };

        // Create a config object for axios so we can reuse a lot of code
        // deleteEndpoint is the same for updates. Just action is different
        const config = {
          method: 'put',
          url: `${this.deleteEndpoint}/${id}`,
          data: json,
        };

        axiosClient
          .request(config)
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
          });
      }
    },

    /**
     * Deletes or undeletes row
     */
    deleteOrUndeleteRow(id) {
      if (this.workingWithDeleted) {
        this.undeleteRow(id);
      } else {
        this.deleteRow(id);
      }
    },

    /**
     * Deletes (or undeletes) multiple rows
     */
    deleteRows(selectedRows) {
      const selectedRowIds = selectedRows.map(row => row.id);
      selectedRowIds.filter(id => this.IsExistingRecord(id)).forEach(
        id => this.deleteOrUndeleteRow(id),
      );
      this.tableReference.removeRows('id', selectedRowIds);
      this.tableReference.clearSelected();

      if (!this.workingWithDeleted && this.afterDeleteRows) {
        this.afterDeleteRows();
      } else if (this.workingWithDeleted && this.afterUndeleteRows) {
        this.afterUndeleteRows();
      }
    },
  },

};
</script>
