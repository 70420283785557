<!--
Copyright 2020 ACCEL Compliance

Home/landing page for the client application
-->
<template>
  <b-container fluid>
    <b-row align-h="between">
      <b-col>
      <img src="../vue-reusable-components/assets/greenlight-license-logo.png" width="225px" />
      </b-col>
      <b-col
        class="text-right"
        align-self="center"
        v-if="clientOptions.length > 2 || companyOptions.length > 2"
      >
        <div class="flex-shrink-1 d-inline-flex text-nowrap">
          <span class="pt-1 mr-1">Show dashboard data for:</span>
          <b-form-select
            class="ml-1"
            v-if="clientOptions.length > 2"
            v-model="overrideClientId"
            :options="clientOptions"
            size="sm"
            @change="handleChangeOverrideClientId()"
          ></b-form-select>
          <b-form-select
            class="ml-1"
            v-model="overrideCompanyId"
            :options="companyOptions"
            size="sm"
            @change="handleChangeOverrideCompanyId()"
          ></b-form-select>
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-4">
      <!--
        Important notifications.  Includes outstanding profile entries
        and other messages to the user
      -->
      <b-col class="card border-dark mx-2">
        <b-overlay :show="!summaryProfileDataLoaded" spinner-variant="info">
          <b-container fluid>
            <b-row align-v="center" class="mt-2">
              <b-col align-self="center" class="card-header">Important Notifications</b-col>
            </b-row>
            <b-row>
              <b-col>
                <ul>
                  <!--
                    If they have more than 0 outstanding profile entry forms then
                    create a link to a modal dialog showing a table of the entries
                  -->
                  <li v-if="outstandingProfileEntryCount > 0 && !producerOnly" class="py-1">
                    You have
                    <span
                      v-b-modal.oustanding-pes
                      class="info-link"
                    >{{outstandingProfileEntryCount}} profile forms</span>
                    that have been outstanding for more than 2 weeks
                  </li>
                  <li v-if="outstandingProfileEntryCount > 0 && producerOnly" class="py-1">
                    You have a profile form that has been outstanding for more than 2 weeks
                  </li>

                  <!--
                    If they have more than 0 upcoming CE items then
                    create a link to a modal dialog showing a table of the entries
                  -->
                  <li v-if="upcomingCECount > 0 && !producerOnly" class="py-1">
                    <span v-b-modal.upcoming-ces class="info-link">These producers</span>
                    must update their CE in less than 30 days
                  </li>
                  <li v-if="upcomingCECount > 0 && producerOnly" class="py-1">
                    You must update your CE in less than 30 days
                  </li>

                  <!--
                    Any system notifications
                  -->
                  <li v-for="notification in notifications" :key="notification" class="py-1">
                    <span v-html="notification"></span>
                  </li>

                  <!--
                    If there are no notifications at all
                  -->
                  <li v-if="outstandingProfileEntryCount === 0 &&
                      upcomingCECount === 0 &&
                      ((! notifications) || (notifications.length === 0))"
                    class="py-1">
                    <span
                      class="none-available-text"
                    >You have no notifications at this time</span>
                  </li>
                </ul>

                <!--
                  Modal dialog with a table of outstanding profile entry forms
                -->
                <b-modal
                  id="oustanding-pes"
                  size="lg"
                  centered
                  scrollable
                  ok-only
                  header-bg-variant="secondary"
                  header-text-variant="light"
                  title="Outstanding Profile Forms"
                >
                  <b-table
                    striped
                    hover
                    sticky-header
                    head-variant="light"
                    :fields="profileEntryFields"
                    :items="profileEntries"
                    small
                  ></b-table>
                </b-modal>

                <!--
                  Modal dialog with a table of upcoming CEs
                -->
                <b-modal
                  id="upcoming-ces"
                  size="lg"
                  centered
                  scrollable
                  ok-only
                  header-bg-variant="secondary"
                  header-text-variant="light"
                  title="Upcoming CE"
                >
                  <b-table
                    striped
                    hover
                    sticky-header
                    head-variant="light"
                    :fields="ceFields"
                    :items="ces"
                    small
                  ></b-table>
                </b-modal>
              </b-col>
            </b-row>
          </b-container>
        </b-overlay>
      </b-col>

      <!--
        Action items.  Give the user a choice of date ranges in the graph
        to see different action items due
      -->
      <b-col class="card border-dark mx-2">
        <b-overlay :show="!summaryActionItemsDataLoaded" spinner-variant="info">
          <b-container fluid>
            <b-row align-v="center" class="mt-2">
              <b-col align-self="center" class="card-header">Open Action Items Snapshot</b-col>
              <b-col align-self="center" class="text-right">
                <b-form-select :value="actionItemsRange" @change="handleActionItemsRangeChange">
                  <b-form-select-option value="pastdue">Past Due</b-form-select-option>
                  <b-form-select-option value="next15">Next 15 Days</b-form-select-option>
                  <b-form-select-option value="next45">Next 45 Days</b-form-select-option>
                  <b-form-select-option value="next60">Next 60 Days</b-form-select-option>
                </b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <GChart
                  type="BarChart"
                  v-if="summaryActionItemsDataLoaded && actionItemsExist"
                  :data="chartDataActionItems"
                  :options="chartOptionsActionItems"
                />
                <span
                  class="none-available-text"
                  v-if="summaryActionItemsDataLoaded && !actionItemsExist"
                >You have no action items due in the next 60 days</span>
              </b-col>
            </b-row>
            <b-row align-h="end" class="mt-2">
              <b-col class="text-right">
                <b-link :to="actionItemsLink">View Action Items</b-link>
              </b-col>
            </b-row>
          </b-container>
        </b-overlay>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <!--
        Renewal items.  Show a rolling 7 month window of completed and pending renewals
      -->
      <b-col class="card border-dark mx-2">
        <b-overlay :show="!summaryRenewalDataLoaded" spinner-variant="info">
          <b-container fluid>
            <b-row align-v="center" class="mt-2">
              <b-col align-self="center" class="card-header">License Renewal Status</b-col>
            </b-row>

            <b-row>
              <b-col>
                <GChart
                  v-if="summaryRenewalDataLoaded"
                  type="ColumnChart"
                  :data="chartDataLicenseRenewal"
                  :options="chartOptionsLicenseRenewal"
                />
              </b-col>
            </b-row>
            <b-row align-h="end">
              <b-col class="text-right">
                <b-link to="/reports/licenseRenewal">View Reports</b-link>
              </b-col>
            </b-row>
          </b-container>
        </b-overlay>
      </b-col>

      <!--
        Filing items.  Show a rolling 7 month window of completed and pending filings
      -->
      <b-col class="card border-dark mx-2">
        <b-overlay :show="!summaryFilingDataLoaded" spinner-variant="info">
          <b-container fluid>
            <b-row align-v="center" class="mt-2">
              <b-col align-self="center" class="card-header">
                Secretary of State/DBA Filing Status
              </b-col>
            </b-row>

            <b-row align-h="start">
              <b-col>

                <GChart
                  v-if="summaryFilingDataLoaded && sosCountsExist && !producerOnly"
                  type="ColumnChart"
                  :data="chartDataFilingStatus"
                  :options="chartOptionsFilingStatus"
                />
                <span
                  v-if="summaryFilingDataLoaded && !sosCountsExist && !producerOnly">
                  <br>
                  ACCEL now offers Secretary of State services including domestic entity formation,
                  foreign qualification filings and Managed Annual Report filings, allowing you to
                  hand-off all of your licensing and Secretary of State regulatory needs to ACCEL.
                  For more information please contact the Analyst team at
                  <b-link href="mailto:analysts@accelcompliance.com"
                    class="card-link">analysts@accelcompliance.com</b-link>
                  <br><br>
                </span>
                <span
                  v-if="summaryFilingDataLoaded && producerOnly">
                  <br>
                  This window normally shows data related to ACCEL’s Secretary of State services,
                  including domestic entity formation, foreign qualification filings and Managed
                  Annual Report filings. This company-level data is not visible
                  to you in the Producer view.
                  <br><br>
                </span>
              </b-col>
            </b-row>
            <b-row align-h="end">
              <b-col class="text-right">
                <b-link
                  v-if="summaryFilingDataLoaded && sosCountsExist"
                 to="/reports/filingsByExpDate">View Reports</b-link>
              </b-col>
            </b-row>
          </b-container>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>

<style scoped>
.card-header {
  font-size: 20px !important;
  color: #000000 !important;
  background-color: #ffffff;
  border-bottom: 0px !important;
}

.info-link {
  text-decoration: underline;
}
.none-available-text {
  font-style: italic;
  color: #999999 !important;
}
</style>

<script>
import moment from 'moment';
import { GChart } from 'vue-google-charts';
import axiosClient from '../vue-reusable-components/scripts/axiosclient';

export default {
  name: 'Home',
  components: {
    GChart,
  },
  data() {
    return {
      toDoCounts: null,
      renewalData: null,
      filingData: null,
      profileEntries: [],
      ces: [],
      notifications: [],

      summaryActionItemsDataLoaded: false,
      summaryRenewalDataLoaded: false,
      summaryFilingDataLoaded: false,
      summaryProfileDataLoaded: false,

      // Allow the user to work with a single client or company
      overrideClientId: null,
      overrideCompanyId: null,

      userSelectedActionItemsRange: null, // the user hasn't selected a range yet
    };
  },

  computed: {
    /**
     * Must be computed because it is determined asynchronously
     * after the component loads
     */
    producerOnly() {
      return this.$root.producerOnly;
    },

    /**
     * Get chart data for license renewal
     */
    chartDataLicenseRenewal() {
      // If we have no data, return empty
      const header = [
        'Month',
        'Completed',
        'Remaining',
        { role: 'annotation' },
      ];

      if (!this.renewalData) {
        return [
          header,
          ['JAN', 0, 0, '0'], // Need data or an error shows
        ];
      }

      // otherwise, build the data
      const data = Object.entries(this.renewalData).map((entry) => {
        const date = moment(entry[0], 'YYYY-MM');
        const completeCount = entry[1].complete;
        const pendingCount = entry[1].pending;
        const totalCount = completeCount + pendingCount;
        const dataRow = [
          date.format('MMM').toUpperCase(),
          completeCount,
          pendingCount,
          `${totalCount}`,
        ];
        return dataRow;
      });

      data.unshift(header);
      return data;
    },

    /**
     * Chart options for license renewal.  Almost all static except that we make
     * the max value 10% more than the highest value so that the value are sure to render
     * outside the bars as intended
     */
    chartOptionsLicenseRenewal() {
      let maxValue = 0;

      if (this.renewalData) {
        Object.values(this.renewalData).forEach((value) => {
          const totalCount = value.complete + value.pending;
          if (maxValue < totalCount) maxValue = totalCount;
        });
      }

      maxValue *= 1.1;

      return {
        height: 350,
        isStacked: true,
        colors: ['#0065a4', '#8dc740'],
        legend: { position: 'top' },
        chartArea: { width: '90%', height: '75%' },
        vAxis: {
          textPosition: 'none', // hides the y-axis labels
          gridlines: { count: 0 }, // hides the gridlines
          maxValue,
          minValue: 0,
        },
        annotations: {
          alwaysOutside: true,
          textStyle: {
            // The color of the text.
            color: '#000000',
          },
        },
      };
    },

    /**
     * Get chart data for filing status
     */
    chartDataFilingStatus() {
      // If we have no data, return empty
      const header = [
        'Month',
        'Completed',
        'Remaining',
        { role: 'annotation' },
      ];

      if (!this.filingData) {
        return [
          header,
          ['JAN', 0, 0, '0'], // Need data or an error shows
        ];
      }

      // otherwise, build the data
      const data = Object.entries(this.filingData).map((entry) => {
        const date = moment(entry[0], 'YYYY-MM');
        const completeCount = entry[1].complete;
        const pendingCount = entry[1].pending;
        const totalCount = completeCount + pendingCount;
        const dataRow = [
          date.format('MMM').toUpperCase(),
          completeCount,
          pendingCount,
          `${totalCount}`,
        ];
        return dataRow;
      });

      data.unshift(header);
      return data;
    },

    /**
     * Chart options for filing status.  Almost all static except that we make
     * the max value 10% more than the highest value so that the value are sure to render
     * outside the bars as intended
     */
    chartOptionsFilingStatus() {
      let maxValue = 0;

      if (this.filingData) {
        Object.values(this.filingData).forEach((value) => {
          const totalCount = value.complete + value.pending;
          if (maxValue < totalCount) maxValue = totalCount;
        });
      }

      maxValue *= 1.1;

      return {
        height: 350,
        isStacked: true,
        colors: ['#0065a4', '#8dc740'],
        legend: { position: 'top' },
        chartArea: { width: '90%', height: '75%' },
        vAxis: {
          textPosition: 'none', // hides the y-axis labels
          gridlines: { count: 0 }, // hides the gridlines
          maxValue,
          minValue: 0,
        },
        annotations: {
          alwaysOutside: true,
          textStyle: {
            // The color of the text.
            color: '#000000',
          },
        },
      };
    },

    /**
     * If the user has manually selected a range return that.
     * When the component loads though, and the user hasn't selected yet,
     * default to the first entry that has a value
     */
    actionItemsRange() {
      if (this.userSelectedActionItemsRange) { return this.userSelectedActionItemsRange; }
      if (this.toDoCounts) {
        if (this.toDoCounts.pastdue.total > 0) return 'pastdue';
        if (this.toDoCounts.next15.total > 0) return 'next15';
        if (this.toDoCounts.next45.total > 0) return 'next45';
        return 'next60';
      }
      return null;
    },

    /**
     * Returns true if the action items have been loaded and there is at least 1 item
     * in the next 60 or past due, false otherwise
     */
    actionItemsExist() {
      if (this.toDoCounts) {
        return this.toDoCounts.total > 0;
      }
      return false;
    },

    /**
     * Get the chart data for action items, depending on which date range they've
     * selected and assuming we have data back from server
     */
    chartDataActionItems() {
      let accelCount = 0;
      let clientCount = 0;
      const { actionItemsRange } = this;
      if (this.toDoCounts && actionItemsRange) {
        accelCount = this.toDoCounts[actionItemsRange].ACCEL;
        clientCount = this.toDoCounts[actionItemsRange].Client;
      }

      // Return chart data, including header
      return [
        ['Owner', 'Items', { role: 'style' }, { role: 'annotation' }],
        ['ACCEL', accelCount, '#8dc740', `${accelCount}`],
        ['Client', clientCount, '#0065a4', `${clientCount}`],
      ];
    },

    /**
     * Chart options for action items.  Almost all static except that we make
     * the max value 10% more than the highest value so that the value are sure to render
     * outside the bars as intended
     */
    chartOptionsActionItems() {
      let maxValue = 0;

      // Go through all the ranges and find the max value so the scale stays constant
      // when the user selects different time periods
      if (this.toDoCounts) {
        const ranges = ['pastdue', 'next15', 'next45', 'next60'];
        ranges.forEach((range) => {
          maxValue = Math.max(maxValue, this.toDoCounts[range].ACCEL,
            this.toDoCounts[range].Client);
        });
      }

      maxValue *= 1.1;

      return {
        height: 200,
        colors: ['#8dc740', '#0065a4'],
        legend: { position: 'none' },
        animation: { duration: 500 },
        hAxis: {
          minValue: 0,
          maxValue,
          textPosition: 'none', // hides the x-axis labels
          gridlines: { count: 0 }, // hides the gridlines
        },
        chartArea: { width: '80%', height: '80%' },
        annotations: {
          alwaysOutside: true,
          textStyle: {
            // The color of the text.
            color: '#000000',
          },
        },
      };
    },

    actionItemsLink() {
      const { actionItemsRange } = this;
      if (actionItemsRange === 'pastdue') {
        return '/actionitems/pastdue';
      }
      if (actionItemsRange === 'next15') return '/actionitems/15';
      if (actionItemsRange === 'next45') return '/actionitems/45';
      return '/actionitems/60';
    },

    /**
     * The number of outstanding profile entries
     */
    outstandingProfileEntryCount() {
      if (this.profileEntries) {
        return this.profileEntries.length;
      }
      return 0;
    },

    /**
     * The fields (column definitions) for outstanding profile entry
     * table.  Only show the company column if the user has more than
     * one company
     */
    profileEntryFields() {
      if (!this.profileEntries) return [];

      const columns = [
        {
          key: 'name',
          label: 'Name',
          sortable: true,
          class: 'small',
        },
        {
          key: 'company_name',
          label: 'Company',
          sortable: true,
          class: 'small',
        },
        {
          key: 'last_update_date',
          label: 'Email Sent',
          sortable: true,
          class: 'small text-nowrap',
        },
      ];
      // remove the Company name column if only one company or producer only
      if ((this.$root.oneCompanyOnly) || (this.producerOnly)) columns.splice(1, 1);
      return columns;
    },

    /**
     * The number of upcoming CEs
     */
    upcomingCECount() {
      if (this.ces) {
        return this.ces.length;
      }
      return 0;
    },

    /**
     * Returns true if there are any SOS counts available,
     * false otherwise.  Display a different message to the
     * user if there are none
     */
    sosCountsExist() {
      if (!this.filingData) return false;

      return Object.entries(this.filingData).some((entry) => {
        const completeCount = entry[1].complete;
        const pendingCount = entry[1].pending;
        const totalCount = completeCount + pendingCount;
        return (totalCount > 0);
      });
    },

    /**
     * The fields (column definitions) for upcoming CE table
     * table.  Only show the company column if the user has more than
     * one company
     */
    ceFields() {
      if (!this.ces) return [];

      const columns = [
        {
          key: 'name',
          label: 'Name',
          sortable: true,
          class: 'small',
        },
        {
          key: 'company_name',
          label: 'Company',
          sortable: true,
          class: 'small',
        },
        {
          key: 'due_date',
          label: 'Due Date',
          sortable: true,
          class: 'small text-nowrap',
        },
      ];
      // remove the Company name column if only one company or producer only
      if ((this.$root.oneCompanyOnly) || (this.producerOnly)) columns.splice(1, 1);
      return columns;
    },

    /**
     * Returns:
     *    the client id - If there is only 1 client available
     *    the selected client id - If the user has selected a client id
     *    null - If there are no clients, or more than 1 client but the user hasn't selected
     */
    selectedOrDefaultClientId() {
      const { clientList } = this.$root;
      if (!clientList || clientList.length === 0) return null;

      if (clientList.length === 1) return clientList[0].id;
      return this.overrideClientId;
    },

    /**
     * Returns the options for selecting client
     */
    clientOptions() {
      const { clientList } = this.$root;
      if (!clientList || clientList.length === 0) return [];

      const options = clientList.map(client => ({
        value: client.id,
        text: client.name,
      }));
      options.unshift({
        value: null,
        text: '-- All Clients --',
        disabled: false,
      });
      return options;
    },

    /**
     * Returns the options for selecting company
     */
    companyOptions() {
      // Always have a company list, even if not ready yet
      const selectOption = {
        value: null,
        text: 'Please select a client first',
        disabled: true,
      };

      // If we have no clients, or no default, or no selected client, return the option telling
      // the user to select a client
      const { clientList } = this.$root;
      if (!clientList || !this.selectedOrDefaultClientId) return [selectOption];

      const client = clientList.find(
        c => c.id === this.selectedOrDefaultClientId,
      );
      if (!client) return [selectOption];

      const options = client.companies.map(company => ({
        value: company.id,
        text: company.name,
      }));
      options.unshift({
        value: null,
        text: '-- All Companies --',
        disabled: false,
      });
      return options;
    },

    /**
     * Get the override clientid and companyid, if any.
     * Send company id if provided, or client id if provided and no company id
     * otherwise, blank
     */
    overrideIdsForAPICall() {
      if (this.overrideCompanyId) {
        return `?_companyId=${this.overrideCompanyId}`;
      }
      if (this.overrideClientId) {
        return `?_clientId=${this.overrideClientId}`;
      }
      return ''; // otherwise, return empty string
    },
  },

  methods: {
    /**
     * When the user selects an action item range, note the choice
     */
    handleActionItemsRangeChange(selectedVal) {
      this.userSelectedActionItemsRange = selectedVal;
    },

    /**
     * When user changes override client id, clear the override
     * company id and refresh dashboard
     */
    handleChangeOverrideClientId() {
      this.overrideCompanyId = null;
      this.fetchAllSummaryData();
    },

    /**
     * When the user changes the ovveride client id, refresh dashboard
     */
    handleChangeOverrideCompanyId() {
      this.fetchAllSummaryData();
    },

    /**
     * Gets action item information
     */
    async fetchSummaryInfoActionItems() {
      this.summaryActionItemsDataLoaded = false;
      axiosClient
        .get(`services/summarydata/actionitems${this.overrideIdsForAPICall}`)
        .then((res) => {
          const json = res.data;
          this.toDoCounts = json.todo;
          this.summaryActionItemsDataLoaded = true;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },

    /**
     * Gets renewal information to drive the graph
     */
    async fetchSummaryInfoRenewals() {
      this.summaryRenewalDataLoaded = false;
      axiosClient
        .get(`services/summarydata/renewalstatus${this.overrideIdsForAPICall}`)
        .then((res) => {
          const json = res.data;
          this.renewalData = json.renewalStatus;
          this.summaryRenewalDataLoaded = true;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },

    /**
     * Gets filing data to drive the graph
     */
    async fetchSummaryInfoFilings() {
      this.summaryFilingDataLoaded = false;
      axiosClient
        .get(`services/summarydata/filingstatus${this.overrideIdsForAPICall}`)
        .then((res) => {
          const json = res.data;
          this.filingData = json.filingStatus;
          this.summaryFilingDataLoaded = true;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },

    /** Gets old profile entries */
    async fetchSummaryProfileEntryData() {
      this.profileEntries = [];
      this.summaryProfileDataLoaded = false;
      axiosClient
        .get(`services/summarydata/profileentries${this.overrideIdsForAPICall}`)
        .then((res) => {
          const json = res.data;
          this.profileEntries = json.profileEntries;
          this.summaryProfileDataLoaded = true;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },

    /** Gets upcoming CE entries */
    async fetchSummaryCEData() {
      this.ces = [];
      axiosClient
        .get(`services/summarydata/ce${this.overrideIdsForAPICall}`)
        .then((res) => {
          const json = res.data;
          this.ces = json.ce;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },

    /** Gets upcoming CE entries */
    async fetchSummaryNotifications() {
      this.notifications = [];
      axiosClient
        .get(`services/summarydata/notifications${this.overrideIdsForAPICall}`)
        .then((res) => {
          const json = res.data;
          this.notifications = json.notifications;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },

    /** Fetch all summary data */
    fetchAllSummaryData() {
      this.fetchSummaryInfoActionItems();
      this.fetchSummaryInfoRenewals();
      this.fetchSummaryInfoFilings();
      this.fetchSummaryProfileEntryData();
      this.fetchSummaryCEData();
      this.fetchSummaryNotifications();
    },
  },

  /**
   * When creating the component, fetch all the data needed for dashboard
   */
  created() {
    // Restore from the main data store if available
    if (this.$root.dashboardData) {
      Object.assign(this.$data, this.$root.dashboardData);
    } else this.fetchAllSummaryData();
  },

  /*
  watch: {
    overrideClientId() {
      this.fetchAllSummaryData();
    },
    overrideCompanyId() {
      this.fetchAllSummaryData();
    },
  },
  */

  /** Before the component is destroyed, cache data */
  beforeDestroy() {
    this.$root.dashboardData = Object.assign({}, this.$data);
  },
};
</script>
