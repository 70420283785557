// Use this client to add auth calls and connect to the
// the appropriate server url (dev vs test/prod)

import axios from 'axios';

const axiosClient = () => {
  const defaultOptions = {
    baseURL: process.env.VUE_APP_API_SERVER,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // Create instance
  const instance = axios.create(defaultOptions);

  // Allow clients to work with cancel tokens
  instance.CancelToken = axios.CancelToken;

  // Set the AUTH token for any request
  // The function inside gets executed for every request, so it can handle
  // updating the token
  instance.interceptors.request.use((config) => {
    const theConfig = config;
    const token = sessionStorage.getItem('vue-token');
    theConfig.headers.Authorization = token ? `Bearer ${token}` : '';
    axios.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem('vue-token')}`;
    return theConfig;
  });

  return instance;
};

export default axiosClient();
