<!--
Copyright 2020 ACCEL Compliance

Navigation menu for client application
-->
<template>
  <b-navbar toggleable="lg" type="light" variant="light" sticky class="mb-3">
    <b-navbar-brand>
      <img src="../vue-reusable-components/assets/logo.png" alt="Accel Logo" width="50%" />
    </b-navbar-brand>

    <!-- Dashboard -->
    <b-navbar-nav class="ml-3">
      <b-nav-item to="/" :active="activeSection === 'dashboard'">
        Dashboard
      </b-nav-item>

      <!-- Onboarding -->
      <b-nav-item-dropdown class="ml-3"
        v-if="onboardingList.length > 0"
        :class="onboardingActiveClass"
        :toggleClass="onboardingActiveClass"
        text="Onboarding "
      >

        <b-dropdown-item
          v-for="onboarding in onboardingList"
          :key="onboarding.id"
          :active="activeSection === 'onboarding' && activeSubSection === '' + onboarding.id"
          class="pl-2"
          :to="'/onboarding/' + onboarding.id + '?cn='
            + encodeURIComponent(onboarding.company_name)"
        >{{onboarding.company_name}}</b-dropdown-item>
      </b-nav-item-dropdown>

      <!-- Action items -->
      <b-nav-item class="ml-3" to="/actionitems" :active="activeSection === 'actionitems'">
        Action Items
      </b-nav-item>

      <!-- reports -->
      <b-nav-item-dropdown class="ml-3"
        :class="reportsActiveClass"
        :toggleClass="reportsActiveClass"
        text="Reports "
      >
        <b-dropdown-group header="Department of Insurance Reports">
        <b-dropdown-item
          :active="activeSection === 'reports' && activeSubSection === 'allLicenses'"
          class="pl-2"
          to="/reports/allLicenses"
        >All Licenses - Active/Pending/Inactive/DNM</b-dropdown-item>
        <b-dropdown-item
          :active="activeSection === 'reports' && activeSubSection === 'licenseRenewal'"
          class="pl-2"
          to="/reports/licenseRenewal"
        >Licenses by Expiration Date</b-dropdown-item>
        <b-dropdown-item
          :active="activeSection === 'reports' && activeSubSection === 'licenseState'"
          class="pl-2"
          to="/reports/licenseState"
        >Licenses by State</b-dropdown-item>
        <b-dropdown-item
          :active="activeSection === 'reports' && activeSubSection === 'licenseName'"
          class="pl-2"
          to="/reports/licenseName"
        >Licenses by Name</b-dropdown-item>
        <b-dropdown-item
          :active="activeSection === 'reports' && activeSubSection === 'licenseNumber'"
          class="pl-2"
          to="/reports/licenseNumber"
        >License Number/NPN</b-dropdown-item>
        <b-dropdown-item
          :active="activeSection === 'reports' && activeSubSection === 'affiliation'"
          class="pl-2"
          to="/reports/affiliation"
        >Affiliations</b-dropdown-item>
        </b-dropdown-group>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-group header="Secretary of State and DBA Reports">
        <b-dropdown-item
          :active="activeSection === 'reports' && activeSubSection === 'filingsByExpDate'"
          class="pl-2"
          to="/reports/filingsByExpDate"
        >Corporate Registrations by Expiration Date</b-dropdown-item>
        <b-dropdown-item
          :active="activeSection === 'reports' && activeSubSection === 'filingsByName'"
          class="pl-2"
          to="/reports/filingsByName"
        >Company and DBA Names</b-dropdown-item>
        <b-dropdown-item
          :active="activeSection === 'reports' && activeSubSection === 'acquisitionDatesByRegAgent'"
          class="pl-2"
          to="/reports/acquisitionDatesByRegAgent"
        >Effective Dates and Registered Agent</b-dropdown-item>
        </b-dropdown-group>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-group header="Applications and Renewals">
        <b-dropdown-item
          :active="activeSection === 'reports' && activeSubSection === 'applicationsRenewals'"
          class="pl-2"
          to="/reports/applicationsRenewals"
        >Applications and Renewals</b-dropdown-item>
        </b-dropdown-group>
      </b-nav-item-dropdown>

      <!-- documents -->
      <b-nav-item-dropdown class="ml-3"
        v-if="!producerOnly"
        :class="documentsActiveClass"
        :toggleClass="documentsActiveClass"
        text="Document Management "
      >
        <b-dropdown-item
          :active="activeSection === 'documents' && activeSubSection === 'upload'"
          to="/documents/upload"
        >Uploads</b-dropdown-item>
        <b-dropdown-item
          :active="activeSection === 'documents' && activeSubSection === 'docLibrary'"
          to="/documents/docLibrary"
        >Library</b-dropdown-item>
      </b-nav-item-dropdown>

      <!-- link to admin dashboard -->
      <!--
      <b-nav-item class="ml-3"
        href="http://localhost:8082"
        target="_admin_tab"
        href1="https://client-beta.accelcompliance.com"
        >
        Admin Dashboard
      </b-nav-item>
      -->
    </b-navbar-nav>

    <!-- Right aligned nav items -->
    <b-navbar-nav align="left" class="ml-auto">
      <b-nav-item-dropdown right size="sm" class="small">
        <!-- Using 'button-content' slot -->
        <template v-slot:button-content>
          <font-awesome-icon :icon="['fas', 'user-circle']" />
          <span class="px-1"><em>{{ name }}</em></span>
        </template>
        <b-dropdown-item size="sm" class="small" href="#" @click="logout()">
          <font-awesome-icon :icon="['fas', 'sign-out-alt']" />&nbsp;&nbsp;Sign
          Out
        </b-dropdown-item>
      </b-nav-item-dropdown>

    </b-navbar-nav>
  </b-navbar>

</template>

<!-- Styles specific to navigation.  Active and hover over styling mostly -->
<style scoped>
.navbar.navbar-light {
  background-color: white !important;
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.25);
}

.navbar-nav >>> .b-nav-dropdown.active,
.navbar-nav >>> .nav-link:hover,
.navbar-nav >>> .nav-link.active {
  color: #0066a4 !important;
  -webkit-box-shadow: 0 8px 1px -6px #8dc740;
  box-shadow: 0 8px 1px -6px #8dc740;
  transition: 0.6s;
}
</style>

<script>

export default {
  props: {
    activeSection: {
      type: String,
    },
    activeSubSection: {
      type: String,
    },
  },

  data() {
    return {
      name: '',
      showNavbarCounts: this.$root.userOptions.options.showNavbarCounts,
    };
  },

  computed: {
    /**
     * Must be computed because it is determined asynchronously
     * after the component loads
     */
    producerOnly() {
      return this.$root.producerOnly;
    },

    /**
     * Computed property that will mark the "onboarding" nav selection
     * as active if that's the what the user has selected
     */
    onboardingActiveClass() {
      return this.activeSection === 'onboarding' ? 'active' : '';
    },

    /**
     * Computed property that will mark the "reports" nav selection
     * as active if that's the what the user has selected
     */
    reportsActiveClass() {
      return this.activeSection === 'reports' ? 'active' : '';
    },

    /**
     * Computed property that will mark the "document management" nav selection
     * as active if that's the what the user has selected
     */
    documentsActiveClass() {
      return this.activeSection === 'documents' ? 'active' : '';
    },

    /**
     * Wrapper for getting the onboarding list from the main data.
     * Always make sure a list is returned.
     */
    onboardingList() {
      let { onboardingList } = this.$root;
      if (!onboardingList) {
        onboardingList = [];
      }
      return onboardingList;
    },

  },

  methods: {
    /**
     * Logs user out and redirects them back to same page
     */
    logout() {
      const { keycloak } = window;
      keycloak.logout({ redirectUri: window.location });
    },

    /**
     * Update nav bar option back into local storage
     */
    updateOptionsNavBarCounts(checked) {
      this.$root.userOptions.setOption('showNavbarCounts', checked);
    },

  },
  mounted() {
    if (sessionStorage.name) {
      this.name = sessionStorage.name;
    }
  },
};
</script>
