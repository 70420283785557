
/**
 Copyright 2021 ACCEL Compliance

validation functions for email
 */
const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

/**
 * Validates that the email address is valid
 * @param {string} emailAddress The email address to validate
 */
export function ValidateEmail(emailAddress) {
  // return true if there is no email.  Required validation should be separate
  if (!emailAddress) return true;
  if (emailAddress.trim().match(EMAIL_REGEX)) return true;
  return false;
}

/**
 * Validates that the email address is valid, but allows for a comma or semicolon-delimited
 * list of email addresses
 * @param {string} emailAddress The email address to validate
 */
export function ValidateMultipleEmails(emailAddress) {
  // return true if there is no email.  Required validation should be separate
  if (!emailAddress) return true;

  const emails = emailAddress.split(/[;,]/);

  // reduce by checking on every element
  const allValid = emails.reduce((allMatch, email) => allMatch && ValidateEmail(email), true);
  return allValid;
}
