<!--
Copyright 2020 ACCEL Compliance

Renders the recently uploaded documents along with the ability to upload new
-->
<template>
  <div>
        <report-table
          v-if="reportUrl != null"
          id="report_table"
          ref="report_table"
          title="Recently Uploaded Documents"
          selectable
          date-filter-tooltip="Select the uploaded timeframe"
          date-filter-field="sent_date"
          :date-filter-default-value="{ min: '-30 d' }"
          :date-filter-options="dateFilterOptions"
          :report-url="reportUrl"
        >

          <!-- A custom formatted header cell for creating the upload button -->
          <template v-slot:right-of-title>
            <div class="small">
            <b-button
              v-b-modal.modal-upload
              size="sm"
              class="small"
              variant="primary"
              v-b-tooltip.hover.lefttop
              title="Upload new document"
              >
              <font-awesome-icon :icon="['fa', 'file-upload']" />
                <span class="ml-3">Upload Document</span>
            </b-button>

            <!-- modal dialog to confirm changing status -->
            <b-modal
              id="modal-upload"
              centered
              size="xl"
              no-close-on-backdrop
              title="Secure Document Upload Utility"
              header-bg-variant="secondary"
              header-text-variant="light"
              button-size="sm"
              ok-title="Upload Document"
              :ok-disabled="fileUploadDisabled"
              @show="openFileUploadModal()"
              @ok="handleFileUploadOk"
              @cancel="cancelUpload"
              @hide="handleUploadHide"
              >
                <b-container class="text-left mb-1">
                  <b-row align-h="start" class="mb-4">
                    <b-col cols="12"><p>
                      To securely upload your licenses, applications and other documents
                      to the ACCEL GreenLight™ License Management System, simply click the button
                      below and follow the prompts. Please <b>do not email documents</b>,
                      as we can not ensure their security.</p>
                      <p>
                      Once uploaded, your ACCEL Compliance team will review the documents,
                      take appropriate action and electronically file the
                      document in your account. You will receive confirmation of document
                      receipt from ACCEL.</p>

                      Select a company below and a document to upload.  Only plain text (.txt),
                      PDF, and image files are permitted.
                    </b-col>
                  </b-row>
                  <b-form-row align-v="center" align-h="start"
                    class="mt-2"
                    v-if="clientOptions.length > 2">
                    <b-col cols="2">Client: </b-col>
                    <b-col cols="4">
                        <b-form-select v-model="selectedClientId"
                          :options="clientOptions"
                          size="sm"
                          @change="clearCompanyId()">
                        </b-form-select>
                    </b-col>
                    <b-col>&nbsp;</b-col> <!-- force dropdown left -->
                  </b-form-row>
                  <b-form-row align-v="center" align-h="start"
                    class="mt-2">
                    <b-col cols="2">Company: </b-col>
                    <b-col cols="4">
                        <b-form-select v-model="selectedCompanyId"
                          :options="companyOptions"
                          size="sm">
                        </b-form-select>
                    </b-col>
                    <b-col>&nbsp;</b-col> <!-- force dropdown left -->
                  </b-form-row>

                  <b-form-row  align-v="center" align-h="start"
                    class="mt-2">
                    <b-col cols="2">File to upload: </b-col>
                    <b-col cols="4">

                      <!--
                        use "no-drop" for now because drag/drop bypasses the setting for
                        not allowing multiple files/directories, and the accept type.
                        Basically, you can drag anything in.  We'd need further javascript
                        validation to restrict this
                       -->
                      <b-form-file
                        size="sm"
                        accept=".txt, .pdf, .png, .jpg, .jpeg, .gif"
                        v-model="fileToUpload"
                        placeholder="Choose a file..."
                        drop-placeholder="Drop file here..."
                      >
                      </b-form-file>
                    </b-col>
                    <b-col>&nbsp;</b-col> <!-- force dropdown left -->
                  </b-form-row>
                  <b-form-row align-h="start" class="mt-4" v-if="fileCurrentlyUploading">
                    <b-col cols="3">Upload status: </b-col>
                    <b-col>
                      <b-progress
                        variant="success"
                        :value="fileUploadPercentage"
                        animated
                      ></b-progress>
                    </b-col>
                  </b-form-row>
                </b-container>
            </b-modal>
            </div>
          </template>

        </report-table>
  </div>
</template>

<script>
import moment from 'moment';
import ReportTable from '../vue-reusable-components/components/ReportTable.vue';
import axiosClient from '../vue-reusable-components/scripts/axiosclient';

export default {
  name: 'DocumentsUpload',
  components: {
    ReportTable,
  },
  data() {
    return {
      reportUrl: 'services/report?view=docInbox',
      selectedClientId: null,
      selectedCompanyId: null,
      fileToUpload: null,
      fileUploadPercentage: 0,
      fileCurrentlyUploading: false,
      fileCancelTokenSource: null,

      // Options for date filtering
      dateFilterOptions: [
        { value: { min: '-30 d' }, text: 'Past 30 Days' },
        { value: { min: '-90 d' }, text: 'Past 90 Days' },
        { value: { min: '-100 y' }, text: 'All' },
      ],
    };
  },
  computed: {
    /**
     * Returns true if the file upload OK button should be disabled because
     * we don't have all the required inputs, or if we are currently uploading
     */
    fileUploadDisabled() {
      return (this.fileCurrentlyUploading || !this.fileToUpload || !this.selectedCompanyId);
    },

    /**
     * Returns:
     *    the client id - If there is only 1 client available
     *    the selected client id - If the user has selected a client id
     *    null - If there are no clients, or more than 1 client but the user hasn't selected
     */
    selectedOrDefaultClientId() {
      const { clientList } = this.$root;
      if ((!clientList) || (clientList.length === 0)) return null;

      if (clientList.length === 1) return clientList[0].id;
      return this.selectedClientId;
    },

    /**
     * Returns the options for selecting client
     */
    clientOptions() {
      const { clientList } = this.$root;
      if ((!clientList) || (clientList.length === 0)) return [];

      const options = clientList.map(client => ({
        value: client.id,
        text: client.name,
      }));
      options.unshift({ value: null, text: 'Please select a client', disabled: true });
      return options;
    },

    /**
     * Returns the options for selecting company
     */
    companyOptions() {
      // Always have a company list, even if not ready yet
      const selectOption = { value: null, text: 'Please select a client first', disabled: true };

      // If we have no clients, or no default, or no selected client, return the option telling
      // the user to select a client
      const { clientList } = this.$root;
      if (!clientList || !this.selectedOrDefaultClientId) return [selectOption];

      const client = clientList.find(c => c.id === this.selectedOrDefaultClientId);
      if (!client) return [selectOption];

      const options = client.companies.map(company => ({
        value: company.id,
        text: company.name,
      }));
      options.unshift({ value: null, text: 'Please select a company', disabled: true });
      return options;
    },
  },

  methods: {
    clearCompanyId() {
      this.selectedCompanyId = null;
    },

    /**
     * Prepares the modal when opening for upload
     */
    openFileUploadModal() {
      this.fileToUpload = null;
      this.fileUploadPercentage = 0;
      this.fileCurrentlyUploading = false;
    },

    /**
     * When the user clicks okay to upload file.  Prevent modal close
     */
    handleFileUploadOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      this.uploadFile(bvModalEvt.componentId);
    },

    /**
     * When the user tries to close the dialog.  Don't allow if we
     * are currently uploading, the user must click cancel button
     */
    handleUploadHide(bvModalEvt) {
      if (this.fileCurrentlyUploading) {
        // Prevent modal from closing
        bvModalEvt.preventDefault();
      }
    },

    /**
     * Cancels the current upload
     */
    cancelUpload() {
      if (this.fileCurrentlyUploading) {
        this.fileCancelTokenSource.cancel('File upload canceled by user');
      }
      this.fileCurrentlyUploading = false;
    },

    /**
     * Uploads file to the server
     */
    async uploadFile(modalId) {
      // debugger
      this.fileCurrentlyUploading = true;
      const path = `services/company/${this.selectedCompanyId}/document`;
      const formData = new FormData();
      formData.append('file', this.fileToUpload);
      const vue = this;

      // Must create a new cancel token for each request
      // or all future requests will be canceled after
      // canceling once
      const { CancelToken } = axiosClient;
      this.fileCancelTokenSource = CancelToken.source();

      // Fields we will use to add data into the table at the end
      const fileName = this.fileToUpload.name;
      const nowString = moment().format('YYYY-MM-DD');
      const companyNameOption = this.companyOptions
        .find(option => option.value === this.selectedCompanyId);
      const companyName = (companyNameOption ? companyNameOption.text : '');

      await axiosClient
        .post(path,
          formData,
          {
            cancelToken: vue.fileCancelTokenSource.token,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress(progressEvent) {
              vue.fileUploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100), 10,
              );
            },
          })
        // eslint-disable-next-line no-unused-vars
        .then((res) => {
          // need to add this record to the existing data for immediate showing
          const json = res.data;
          const newRow = {
            company_name: companyName,
            document_id: json.document_id,
            filename: fileName,
            sent_date: nowString,
            owner: sessionStorage.name,
          };
          this.$refs.report_table.addRow(newRow);
        })
        .catch((error) => {
          // eslint-disable-next-line
            console.error(error);
        })
        .finally(() => {
          vue.fileCurrentlyUploading = false;
          // Hide the modal manually
          this.$nextTick(() => {
            vue.$bvModal.hide(modalId);
          });
        });
    },


  },
};
</script>
