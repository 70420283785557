<!--
Copyright 2020 ACCEL Compliance

App is the starting point for the application.  It contains the router for handling
navigation, and makes any calls needed when the application initializes
-->
<template>
  <div id="app">
    <keep-alive>
      <router-view @onboarding-submitted="fetchOnboarding"/>
     </keep-alive>
  </div>
</template>

<style>
  body {
    -webkit-font-smoothing: antialiased;
    font-family: 'Source Sans Pro',sans-serif;
    font-weight: 400;
  }
</style>

<script>
import axiosClient from './vue-reusable-components/scripts/axiosclient';
// eslint-disable-next-line import/extensions
import '@fontsource/source-sans-pro';

export default {
  name: 'App',
  methods: {
    /**
     * Gets summary data and puts in into the main data object
     */
    async fetchSummaryInfoClients() {
      const dataRoot = this.$root;
      axiosClient
        .get('services/summarydata/clientscompanies')
        .then((res) => {
          const json = res.data;

          // Set a flag if the user has access to only 1 company
          let oneCompanyOnly = false;
          if (json.clients && json.clients.length === 1 && json.clients[0].companies.length === 1) {
            oneCompanyOnly = true;
          }
          dataRoot.oneCompanyOnly = oneCompanyOnly;

          // Set a flag if the user has only producer access
          let producerOnly = false;
          if (!json.clients || json.clients.length === 0) {
            producerOnly = true;
          }
          dataRoot.producerOnly = producerOnly;
          dataRoot.clientList = json.clients;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },

    /**
     * Gets the list of onboarding records this user has access to
     */
    async fetchOnboarding() {
      const dataRoot = this.$root;
      axiosClient
        .get('services/onboarding')
        .then((res) => {
          const json = res.data;
          dataRoot.onboardingList = json.onboardings;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },


  },
  created() {
    this.fetchSummaryInfoClients();
    this.fetchOnboarding();
  },
};
</script>
