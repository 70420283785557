<!-- Copyright ACCEL 2019 -->
<!--
  Template class for re-usable filter entry for report table.
  Each filter entry is composed of a dropdown of column names
  to filter on, a text box for search text for that column,
  and a button to remove the filter so it's no longer used
-->
<template>
  <div class="ml-1 flex-shrink-1 d-inline-flex text-nowrap">
  <b-input-group>
    <b-input-group-prepend>
      <b-form-select
        class="h-auto"
        v-model="value.fieldName"
        :options="options"
        size="sm"
        :autofocus="false"
        v-b-tooltip.hover
        title="Select the column to filter on"
        @change="pushChangeEvent"
        >

        <!-- This slot appears above the options from 'options' prop -->
        <template v-slot:first>
          <option :value="null" disabled>-- Select a column --</option>
        </template>
      </b-form-select>
    </b-input-group-prepend>

    <b-form-tags
      v-model="value.valueList"
      :disabled="!value.fieldName"
      size="sm"
      separator=";"
      remove-on-delete
      placeholder=""
      v-b-tooltip.hover title="Enter multiple 'or' search values for the same column.
      A semi-colon, the Enter key, or the Add button creates a new search item.
      The backspace key or the X icon deletes the search item."
      @input="pushChangeEvent"
      >
    </b-form-tags>

    <b-input-group-append>
      <b-button
        variant="secondary"
        size="sm"
        v-b-tooltip.hover title="Remove filter"
        @click="$emit('remove-filter')">
        <font-awesome-icon :icon="['fas', 'times-circle']" />
      </b-button>
    </b-input-group-append>
  </b-input-group>
  </div>
</template>


<script>
export default {
  name: 'FilterEntry',
  props: {
    value: {
      type: Object,
    },
    valueList: {
      type: Array,
    },
    options: {
      type: Array,
    },
    index: {
      type: Number,
    },
  },

  data() {
    return {
      fieldName: '',
    };
  },

  methods: {
    pushChangeEvent() {
      this.$emit('filter-value-change', this);
    },
  },

};

</script>
