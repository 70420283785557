<!--
Copyright 2020 ACCEL Compliance

Footer for the application
-->
<template>
  <b-container fluid class="footer">
    <b-row class="footer px-5 py-3">
      <b-col>
        <b-link href="https://accelcompliance.com/FAQ/" target="_blank">
          FAQ</b-link>

        <span class="px-2">|</span>
          <b-button variant="link" v-b-modal.modal-contact>Contact Us</b-button>

          <b-modal id="modal-contact"
            centered
            title="Your ACCEL Compliance Team"
            ok-only
            header-bg-variant="secondary"
            header-text-variant="light"
            size="lg"
            >
            <!--
            <b-container fluid>
              <b-row>
                <b-col>
                  For new hires/onboarding agents or to remove/terminate an agent please
                  reach out to the appropriate email below. For new hires, please include
                  name, email address, cell phone number and resident license number or NPN.
                  For terminations, please include the effective date of termination.
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col cols="1"></b-col>
                <b-col cols="4" class="text-nowrapxxx">New Hires/Onboarding:</b-col>
                <b-col cols="7">
                  <b-link href="mailto:newhire@accelcompliance.com"
                  class="card-link">newhire@accelcompliance.com</b-link></b-col>
              </b-row>
              <b-row>
                <b-col cols="1"></b-col>
                <b-col cols="4" class="text-nowrapxxx">Remove Agent/Termination:</b-col>
                <b-col cols="7">
                  <b-link href="mailto:term@accelcompliance.com"
                  class="card-link">term@accelcompliance.com</b-link></b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col>
                  For license updates such as address changes or name changes please
                  reach out to the appropriate email below.
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="1"></b-col>
                <b-col cols="4" class="text-nowrapxxx">Address Changes:</b-col>
                <b-col cols="7">
                  <b-link href="mailto:addressupdate@accelcompliance.com"
                  class="card-link">addressupdate@accelcompliance.com</b-link></b-col>
              </b-row>
              <b-row>
                <b-col cols="1"></b-col>
                <b-col cols="4" class="text-nowrapxxx">Name Changes:</b-col>
                <b-col cols="7">
                  <b-link href="mailto:nameupdate@accelcompliance.com"
                  class="card-link">nameupdate@accelcompliance.com</b-link></b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col>
                  For license applications please reach out to the appropriate email below.
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="1"></b-col>
                <b-col cols="4" class="text-nowrapxxx">New License Applications:</b-col>
                <b-col cols="7">
                  <b-link href="mailto:addressupdate@newapplication@accelcompliance.com"
                  class="card-link">newapplication@accelcompliance.com</b-link></b-col>
              </b-row>
              <b-row>
                <b-col cols="1"></b-col>
                <b-col cols="4" class="text-nowrapxxx">Renewal Notices:</b-col>
                <b-col cols="7">
                  <b-link href="mailto:notices@accelcompliance.com"
                  class="card-link">notices@accelcompliance.com</b-link></b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col cols="5">
                  For Secretary of State questions please contact:</b-col>
                <b-col cols="7">
                  <b-link href="mailto:sos@accelcompliance.com"
                  class="card-link">sos@accelcompliance.com</b-link>
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col cols="5">
                  For all other questions please contact:</b-col>
                <b-col cols="7">
                  <b-link href="mailto:analysts@accelcompliance.com"
                  class="card-link">analysts@accelcompliance.com</b-link>
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col>
                  For Secretary of State questions please contact:
                  <b-link href="mailto:sos@accelcompliance.com"
                  class="card-link">sos@accelcompliance.com</b-link>.
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col>
                  For all other questions please contact:
                  <b-link href="mailto:analysts@accelcompliance.com"
                  class="card-link">analysts@accelcompliance.com</b-link>.
                </b-col>
              </b-row>
            </b-container>
            -->

            <p class="about-header mx-1 mt-1">Agent Management</p>
            <p class="mx-1 mt-n2">
            For new hires/onboarding agents or to remove/terminate an agent please
            reach out to the appropriate email below. For new hires, please include name,
            email address, cell phone number and resident license number or NPN.
            For terminations, please include the effective date of termination.
            </p>
            <p class="mx-5 mt-n2">
            New Hires/Onboarding: <b-link href="mailto:newhire@accelcompliance.com"
            class="card-link">newhire@accelcompliance.com</b-link><br>
            Remove Agent/Termination: <b-link href="mailto:term@accelcompliance.com"
            class="card-link">term@accelcompliance.com</b-link>
            </p>
            <p class="about-header mx-1 mt-4">License Updates</p>
            <p class="mx-1 mt-n2">
            For license updates such as address changes or name changes please reach
            out to the appropriate email below.
            </p>
            <p class="mx-5 mt-n2">
            Address Changes: <b-link href="mailto:addressupdate@accelcompliance.com"
            class="card-link">addressupdate@accelcompliance.com</b-link><br>
            Name Changes: <b-link href="mailto:nameupdate@accelcompliance.com"
            class="card-link">nameupdate@accelcompliance.com</b-link>
            </p>
            <p class="about-header mx-1 mt-4">License Applications</p>
            <p class="mx-1 mt-n2">
              For license applications please reach out to the appropriate email below.
            </p>
            <p class="mx-5 mt-n2">
            New License Applications: <b-link href="mailto:newapplication@accelcompliance.com"
            class="card-link">newapplication@accelcompliance.com</b-link><br>
            Renewal Notices: <b-link href="mailto:notices@accelcompliance.com"
            class="card-link">notices@accelcompliance.com</b-link>
            </p>
            <p class="about-header mx-1 mt-4">Secretary of State</p>
            <p class="mx-1 mt-n2">
              For Secretary of State questions please contact
              <b-link href="mailto:sos@accelcompliance.com"
            class="card-link">sos@accelcompliance.com</b-link>.
            </p>
            <p class="about-header mx-1 mt-4">Other</p>
            <p class="mx-1 mt-n2">
              For all other questions please contact
              <b-link href="mailto:analysts@accelcompliance.com"
            class="card-link">analysts@accelcompliance.com</b-link>.
            </p>

            <!--
            <b-container fluid>
              <b-row>
                <b-col>
                  <b-link href="mailto:jcirilli@accelcompliance.com"
                    class="card-link">Joan Cirilli</b-link>
                  <br />860-761-8552
                </b-col>
                <b-col>
                  <b-link href="mailto:kwalker@accelcompliance.com"
                    class="card-link">Kim Walker</b-link>
                  <br />860-726-4203
                </b-col>
                <b-col>
                  <b-link
                    href="mailto:ecicchiello@accelcompliance.com"
                    class="card-link"
                  >Eve Cicchiello</b-link>
                  <br />860-726-4211
                </b-col>
              </b-row>
            </b-container>
            -->
          </b-modal>
        <span class="px-2">|</span>
          <b-link href="mailto:feedback@accelcompliance.com?subject=Feedback" target="_blank">
            Feedback</b-link>
      </b-col>
      <b-col class="text-right">
        <span class="blue">©ACCEL Compliance LLC</span> 2023 All Rights Reserved.
        <b-link href="http://accelcompliance.com/disclaimer/" target="_blank">
          Disclaimer and Privacy Policy</b-link>
      </b-col>
    </b-row>
  </b-container>

</template>

<style scoped>
.about-header {
  text-decoration: underline;
  font-size: 16px;
  font-weight: 600;
}

.btn-link,
.footer {
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  color: #919191 ! important;
  font-family: 'Source Sans Pro',sans-serif;
  background-color: #e9e9e9 !important;
}
.footer .blue {
    color: #2479a8 ! important;
}
.footer a {
    color: #919191 ! important;
}
.footer a:hover {
    color: #0066A4!important;
}

/* Use popup-header if we want the title of the popup to be ACCEL blue */
::v-deep .popup-header {
  font-size: 20px !important;
  color: #ffffff !important;
  background-color: #0065a4;
}
</style>


<script>
// eslint-disable-next-line import/extensions
import '@fontsource/source-sans-pro';

export default {
  name: 'Footer',

  data() {
    return {
    };
  },

};
</script>
