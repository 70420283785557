<!--
Copyright 2020 ACCEL Compliance

Helper component for displaying the editable fields and labels for a row
in an editable table.  This helps enforce consistent layout design between
different screens since all rows are controlled here.

Works with either a single column layout (actually 2, one for label one for value)
or a two column layout (actually 4)
-->
<template>
  <b-row align-h="start" align-v="center" :class="`mt-1 flex-shrink-0 flex-nowrap ` + rowClass">

    <!-- used when there is a 2 column layout but no field in column 1 -->
    <b-col :cols="totalCols" v-if="(cols > 1) && (!field1Label)">&nbsp;</b-col>

    <!-- Normal first column -->
    <b-col :cols="labelCols" v-if="field1Label">{{field1Label}}</b-col>
    <edit-field
      v-if="field1Label"
      :row="row"
      :validateFunc="validateFunc"
      :extraStateCondition="field1ExtraStateCondition"
      :type="field1Type"
      :cols="inputCols"
      :fieldName="field1Name"
      :feedback="field1Feedback"
      :placeholder="field1Placeholder"
      :mask="field1Mask"
      :options="field1Options"
      :readOnly="field1ReadOnly"
      :textRows="field1TextRows"
      :checkboxSwitch="field1CheckboxSwitch"
      :minDate="field1MinDate"
      :maxDate="field1MaxDate"
      :labelNoDateSelected="field1LabelNoDateSelected"
      :labelResetButton="field1LabelResetButton"
      :resetButton="field1ResetButton"
      :resetButtonVariant="field1ResetButtonVariant"
      @change="v => $emit('field1-change', v)"
      @input="v => $emit('field1-input', v)"
      @blur="v => $emit('field1-blur', v)"
    />

    <!-- spacing column -->
    <b-col cols="1" sm="auto" v-if="(cols > 1)">
      <div class="px-3">&nbsp;</div>
    </b-col>

    <!-- used when there is a 2 column layout but no field in column 2 -->
    <b-col :cols="totalCols" v-if="(cols > 1) && (!field2Label)">&nbsp;</b-col>

    <!-- Normal 2nd column -->
    <b-col :cols="labelCols" v-if="field2Label">{{field2Label}}</b-col>
    <edit-field
      v-if="field2Label"
      :row="row"
      :validateFunc="validateFunc"
      :extraStateCondition="field2ExtraStateCondition"
      :type="field2Type"
      :cols="inputCols"
      :fieldName="field2Name"
      :feedback="field2Feedback"
      :placeholder="field2Placeholder"
      :mask="field2Mask"
      :options="field2Options"
      :readOnly="field2ReadOnly"
      :textRows="field2TextRows"
      :checkboxSwitch="field2CheckboxSwitch"
      :minDate="field2MinDate"
      :maxDate="field2MaxDate"
      :labelNoDateSelected="field2LabelNoDateSelected"
      :labelResetButton="field2LabelResetButton"
      :resetButton="field2ResetButton"
      :resetButtonVariant="field2ResetButtonVariant"
      @change="v => $emit('field2-change', v)"
      @input="v => $emit('field2-input', v)"
      @blur="v => $emit('field2-blur', v)"
    />

    <!-- extra column for notes -->
    <b-col cols="1">
      <slot name="notes"></slot>
    </b-col>
  </b-row>
</template>


<script>
import EditField from './EditField.vue';

export default {
  name: 'EditFieldRow',
  components: {
    EditField,
  },

  props: {
    row: {
      type: Object,
    },
    rowClass: {
      type: String,
    },
    validateFunc: {
      type: Function,
    },
    cols: {
      type: Number,
      default: 1,
    },
    field1Name: {
      type: String,
    },
    field1Label: {
      type: String,
    },
    field1Feedback: {
      type: String,
    },
    field1Placeholder: {
      type: String,
    },
    field1Mask: {
      type: String,
    },
    field1ExtraStateCondition: {
      type: Boolean,
      default: true,
    },

    // For checkbox
    field1CheckboxSwitch: {
      type: Boolean,
      default: true,
    },

    field1MinDate: {
      type: [String, Date],
    },
    field1MaxDate: {
      type: [String, Date],
    },
    field1LabelNoDateSelected: {
      type: String,
    },
    field1LabelResetButton: {
      type: String,
      default: 'Clear',
    },
    field1ResetButton: {
      type: Boolean,
      default: false,
    },
    field1ResetButtonVariant: {
      type: String,
      default: null,
    },
    field1Options: {
      type: Array,
    },
    field1ReadOnly: {
      type: Boolean,
      default: false,
    },
    field1TextRows: {
      type: Number,
    },
    field1Type: {
      type: String,
      default: 'input',
    },

    field2Name: {
      type: String,
    },
    field2Label: {
      type: String,
    },
    field2Feedback: {
      type: String,
    },
    field2Placeholder: {
      type: String,
    },
    field2Mask: {
      type: String,
    },
    field2ExtraStateCondition: {
      type: Boolean,
      default: true,
    },

    // For checkbox
    field2CheckboxSwitch: {
      type: Boolean,
      default: true,
    },

    field2MinDate: {
      type: [String, Date],
    },
    field2MaxDate: {
      type: [String, Date],
    },
    field2LabelNoDateSelected: {
      type: String,
    },
    field2LabelResetButton: {
      type: String,
    },
    field2ResetButton: {
      type: Boolean,
      default: false,
    },
    field2ResetButtonVariant: {
      type: String,
      default: null,
    },
    field2Options: {
      type: Array,
    },
    field2ReadOnly: {
      type: Boolean,
      default: false,
    },
    field2TextRows: {
      type: Number,
    },
    field2Type: {
      type: String,
      default: 'input',
    },


  },

  data() {
    return {
    };
  },

  computed: {
    labelCols() {
      // if (this.cols === 1) return 4;
      return 2;
    },
    inputCols() {
      if (this.cols === 1) return 9;
      return 3;
    },
    totalCols() {
      return this.labelCols + this.inputCols;
    },
  },

  methods: {
  },
};
</script>
