/**
 * Vue-router for routing links in the application while staying in the app.
 * See https://router.vuejs.org/
 *
 * Note that we use the default mode, hash-mode (all urls have /#).
 * You can't use history mode (no hash) without having more web server
 * configuration.  See https://router.vuejs.org/guide/essentials/history-mode.html
 */
import Vue from 'vue';
import Router from 'vue-router';
import Ping from './vue-reusable-components/components/Ping.vue';
import ActionItems from './components/ActionItems.vue';
import DocumentsUpload from './components/DocumentsUpload.vue';
import Dashboard from './components/Dashboard.vue';
import Layout from './components/Layout.vue';
import ReportGeneric from './components/ReportGeneric.vue';
import OnboardingProducers from './components/OnboardingProducers.vue';

Vue.use(Router);

export default new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      // Default dashboard page
      // Goes to action items
      // Need to specify properties because they are not in the url
      path: '/',
      component: Layout,
      props: {
        activeSectionOverride: 'dashboard',
      },
      children: [
        {
          path: '/',
          component: Dashboard,
        },
      ],
    },
    {
      // Goes to action items
      // Need to specify properties because they are not in the url
      path: '/actionitems',
      component: Layout,
      props: {
        activeSectionOverride: 'actionitems',
      },
      children: [
        {
          path: '/actionitems/',
          component: ActionItems,
        },
        {
          path: '/actionitems/:time',
          component: ActionItems,
        },
      ],
    },


    // Document Management - 2 paths
    // Since they have a similar path structure but use different
    // components, but also use the route parameters, need to
    // split these into two separate routes
    {
      path: '/documents/upload',
      component: Layout,
      props: {
        activeSectionOverride: 'documents',
        activeSubSectionOverride: 'upload',
      },
      children: [
        {
          path: '/',
          component: DocumentsUpload,
        },
      ],
    },
    {
      path: '/documents/docLibrary',
      component: Layout,
      props: {
        activeSectionOverride: 'documents',
      },
      children: [
        {
          path: '/documents/:activeSubSection',
          component: ReportGeneric,
        },
      ],
    },
    {
      // Generic layout that picks up the active
      // section and subsection (report name) from the url
      path: '/reports',
      component: Layout,
      props: {
        activeSectionOverride: 'reports',
      },
      children: [{
        path: '/reports/:activeSubSection',
        component: ReportGeneric,
      }],
    },

    // Onboarding
    {
      path: '/onboarding/:onboardingId',
      component: Layout,
      props: route => ({
        activeSectionOverride: 'onboarding',
        activeSubSectionOverride: route.params.onboardingId,
        companyName: route.query.cn,
        showTitle: false,
      }
      ),
      children: [
        {
          path: '/',
          component: OnboardingProducers,
        },
      ],
    },

    {
      path: '/ping',
      component: Ping,
    },

    {
      // To handle old bookmarks from prior app
      path: '*',
      component: Layout,
      props: {
        activeSectionOverride: 'dashboard',
      },
      children: [
        {
          path: '/',
          component: Dashboard,
        },
      ],
    },
  ],
});
