<!--
Copyright 2020 ACCEL Compliance

Helper component for displaying a single value in an an editable table details section.
This helps enforce consistent layout design between
different screens since all rows are controlled here.
-->
<template>
    <b-col :cols="cols">

      <b-form-checkbox v-if="type === 'checkbox'"
        v-model="row.item.tempData[fieldName]"
        @change="touchInput(row.item, fieldName)"
        :switch="checkboxSwitch"
      />
      <b-form-datepicker v-if="type === 'datepicker'"
        dark
        :min="minDate"
        :max="maxDate"
        :disabled="readOnly"
        :label-no-date-selected="labelNoDateSelected"
        :label-reset-button="labelResetButton"
        :reset-button="resetButton"
        :reset-button-variant="resetButtonVariant"
        v-model="row.item.tempData[fieldName]"
        @hidden="touchInput(row.item, fieldName)"
        :state="validateFunc(row.item, fieldName)"
        @input="$emit('input',row.item.tempData[fieldName])"
      >
      </b-form-datepicker>
      <b-form-textarea v-if="type === 'textarea'"
        autocomplete="new-password"
        :placeholder="placeholder"
        :disabled="readOnly"
        :rows="textRows"
        v-model="row.item.tempData[fieldName]"
        @blur="touchInput(row.item, fieldName);"
        :state="validateFunc(row.item, fieldName)"
      />
      <b-form-select v-if="type === 'select'"
        v-model="row.item.tempData[fieldName]"
        :options="options"
        :disabled="readOnly"
        @change="touchInput(row.item, fieldName); $emit('change',row.item.tempData[fieldName])"
        :state="validateFunc(row.item, fieldName)"
        >
      </b-form-select>
      <b-form-input v-if="type === 'input'"
        autocomplete="new-password"
        :placeholder="placeholder"
        v-mask="mask"
        :disabled="readOnly"
        v-model="row.item.tempData[fieldName]"
        @blur="touchInput(row.item, fieldName); $emit('blur',row.item.tempData[fieldName])"
        :state="validateFunc(row.item, fieldName) && extraStateCondition"
      >
      </b-form-input>
        <b-form-invalid-feedback>{{feedback}}</b-form-invalid-feedback>
    </b-col>
</template>

<script>
import EditableRows from '../../mixins/EditableRows';

export default {
  name: 'EditField',
  mixins: [EditableRows],

  props: {
    row: {
      type: Object,
    },
    validateFunc: {
      type: Function,
      default() {},
    },
    extraStateCondition: {
      type: Boolean,
      default: true,
    },
    cols: {
      type: Number,
      required: true,
    },
    fieldName: {
      type: String,
    },
    feedback: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    mask: {
      type: String,
    },

    // For checkbox
    checkboxSwitch: {
      type: Boolean,
      default: true,
    },

    // For datepicker
    minDate: {
      type: [String, Date],
    },
    maxDate: {
      type: [String, Date],
    },
    labelNoDateSelected: {
      type: String,
    },
    labelResetButton: {
      type: String,
      default: 'Clear',
    },
    resetButton: {
      type: Boolean,
      default: false,
    },
    resetButtonVariant: {
      type: String,
      default: null,
    },

    options: {
      type: Array,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    textRows: {
      type: Number,
      default: 3,
    },
    type: {
      type: String,
      default: 'input',
    },

  },

  data() {
    return {
    };
  },

  methods: {
  },
};
</script>
