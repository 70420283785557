/** Copyright ACCEL 2020 */
/**
 * Standard font-awesome icons needed by the reusable components
 */

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAddressCard as faAddressCardReg,
  faCheckCircle as faCheckCircleReg,
  faClock as faClockReg,
  faIdCard as faIdCardReg,
  faTimesCircle as faTimesCircleReg,
} from '@fortawesome/free-regular-svg-icons';
import {
  faAddressCard,
  faCheck, faCheckCircle,
  faClipboardList,
  faClock,
  faCog,
  faColumns,
  faEdit,
  faEnvelope,
  faFileCsv,
  faFileDownload,
  faFileExport,
  faFilePdf,
  faFileUpload,
  faFilter,
  faFlask,
  faIdCard,
  faInfoCircle,
  faPlusSquare, faSearch,
  faSignOutAlt,
  faTasks,
  faTimesCircle,
  faTrash, faTrashAlt, faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import {
  FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText,
} from '@fortawesome/vue-fontawesome';

export default function initFontAwesome(Vue) {
  library.add(faAddressCard, faAddressCardReg);
  library.add(faClock, faClockReg);
  library.add(faCog);
  library.add(faCheck);
  library.add(faCheckCircle, faCheckCircleReg);
  library.add(faClipboardList);
  library.add(faColumns);
  library.add(faEdit);
  library.add(faEnvelope);
  library.add(faFlask);
  library.add(faFileCsv);
  library.add(faFileDownload);
  library.add(faFileExport);
  library.add(faFileUpload);
  library.add(faFilePdf);
  library.add(faFilter);
  library.add(faIdCard, faIdCardReg);
  library.add(faInfoCircle);
  library.add(faPlusSquare);
  library.add(faSearch);
  library.add(faSignOutAlt);
  library.add(faTasks);
  library.add(faTimesCircle, faTimesCircleReg);
  library.add(faTrash);
  library.add(faTrashAlt);
  library.add(faUserCircle);

  Vue.component('font-awesome-icon', FontAwesomeIcon);
  Vue.component('font-awesome-layers', FontAwesomeLayers);
  Vue.component('font-awesome-layers-text', FontAwesomeLayersText);
}
