<!--
Copyright 2020 ACCEL Compliance

Renders the action items report.  This report uses an override
setting for filtering on dates, so differs slightly from the
generic reporting vue component
-->
<template>
  <div>
        <report-table
          v-if="reportUrl != null"
          id="report_table"
          ref="report_table"
          title="Action Items"
          selectable
          date-filter-tooltip="Select the due date timeframe"
          date-filter-field="due_date"
          :date-filter-default-value="dateFilterDefaultValue"
          :date-filter-options="dateFilterOptions"
          :report-url="reportUrl"
          customSlotName1="days"
        >

          <template v-slot:custom-cell(days)="{row}">
            <!-- special render for negative days -->
            <span v-if="row.item.days < 0"
              class="text-danger"
            >
              {{ row.item.days }}
            </span>

            <!-- otherwise just render the value -->
            <span v-else>
              {{ row.item.days }}
            </span>
          </template>

        </report-table>
  </div>
</template>

<script>
import ReportTable from '../vue-reusable-components/components/ReportTable.vue';

export default {
  name: 'ActionItems',
  components: {
    ReportTable,
  },
  data() {
    return {
      reportUrl: 'services/report?view=toDoAll',
      selectedDate: null,

      // Options for date filtering
      dateFilterOptions: [
        { value: '-1 d', text: 'Past Due' },
        { value: { min: '0 d', max: '15 d' }, text: '15 Days or Less' },
        { value: { min: '0 d', max: '45 d' }, text: '45 Days or Less' },
        { value: { min: '0 d', max: '60 d' }, text: '60 Days or Less' },
        { value: { min: '0 d', max: '90 d' }, text: '90 Days or Less' },
        { value: { nullsOnly: true }, text: 'Exempt' },
        { value: null, text: 'All' },
      ],
    };
  },
  computed: {
    /**
     * Returns -1 if no date option was found in the path, otherwise the
     * appropriate option from the date options
     */
    dateFilterDefaultValue() {
      let optionIndex = 0;
      if (this.selectedDate) {
        if (this.selectedDate === '15') optionIndex = 1;
        else if (this.selectedDate === '45') optionIndex = 2;
        else if (this.selectedDate === '60') optionIndex = 3;
        else if (this.selectedDate === '90') optionIndex = 4;
      }
      return this.dateFilterOptions[optionIndex].value;
    },
  },
  methods: {
    setNavValues(params) {
      let tempParams = params;
      if (params == null) {
        tempParams = this.$route.params; // this happens when we use browser refresh
        if (tempParams == null) tempParams = {}; // Just to avoid null checks
      }
      this.selectedDate = tempParams.time;
    },
  },
  mounted() {
    this.setNavValues(this.$route.params);
  },
  watch: {
    $route(to) {
      this.setNavValues(to.params);
    },
  },
};
</script>
