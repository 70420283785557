import BootstrapVue from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/css/bootstrap.css';
import VueMask from 'v-mask';
import Vue from 'vue';

// Additional font-awesome icons not in the re-useable components
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAddressCard as faAddressCardReg, faIdCard as faIdCardReg,
} from '@fortawesome/free-regular-svg-icons';
import {
  faAddressCard,
  faClipboardList,
  faEnvelope,
  faFileCsv,
  faIdCard,
} from '@fortawesome/free-solid-svg-icons';

import App from './App.vue';
import router from './router';
import initFontAwesome from './vue-reusable-components/scripts/fontawesome';
import AccelKeycloak from './vue-reusable-components/scripts/keycloak';
import UserOptions from './vue-reusable-components/scripts/useroptions';


Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(VueMask);

initFontAwesome(Vue);

// Additional font-awesome icons not in the re-useable components
library.add(faAddressCard, faAddressCardReg, faIdCard,
  faIdCardReg, faEnvelope, faClipboardList, faFileCsv);

// Create an options object
const userOptions = new UserOptions();
const accelKeycloak = new AccelKeycloak('client-app-vue');


function startApp() {
  new Vue({
    router,
    data: {
      userOptions,
      clientList: null,
      // Whether the user has access to only one company
      // Hide company names in this case
      oneCompanyOnly: true,

      // Whether the user is a producer only, some limited access
      producerOnly: true,

      // Dashboard cached data when component is unloaded
      dashboardData: null,

      // Open onboarding activities
      onboardingList: [],
    },
    render: h => h(App),

  }).$mount('#app');
}

accelKeycloak.initAndLaunch(startApp);
