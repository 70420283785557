<!-- Copyright ACCEL 2019 -->
<!--
  Template class for re-usable table header for report table.
  The the template contains functionality for:
    - showing/hiding columns
    - adding/removing filters and doing the filtering
    - exporting to csv
-->
<template>
  <div class="d-flex mb-3">
    <div class="d-inline-flex justify-content-start flex-grow-1">
      <b-dropdown
        v-if="!hideColumnSelector"
        variant="outline-primary"
        size="sm"
        class="small mr-4"
        v-b-tooltip.hover
        title="Select columns to display"
      >
        <template v-slot:button-content>
          <font-awesome-icon :icon="['fas', 'columns']" />
        </template>
        <b-dropdown-form v-for="(header, index) in filterableHeaders" :key="index">
          <b-form-checkbox
            switch
            size="sm"
            @change="$emit('show-hide-column', header.key)"
            v-model="header.displayed"
            class="text-nowrap small"
          >{{ header.label }}</b-form-checkbox>
        </b-dropdown-form>
      </b-dropdown>

      <b-button
        variant="outline-primary"
        size="sm"
        class="small"
        v-b-tooltip.hover
        title="Add filter"
        @click="addFilter()"
      >
        <font-awesome-icon :icon="['fas', 'filter']" />
      </b-button>
      <filter-entry
        v-for="(filter, index) in filterObjects"
        :key="index"
        :index="index"
        v-model="filterObjects[index]"
        :options="filterableSelection"
        @remove-filter="removeFilter(index); $emit('filter-removed', filter)"
        @filter-value-change="updateFilterValues(); $emit('filter-change-value', filter)"
      ></filter-entry>
    </div>
    <div class="d-inline-flex flex-grow-1">
      &nbsp;
      <!-- spacer -->
    </div>

    <!-- optionally allow users to put extra before export -->
    <div class="d-inline-flex justify-content-end ml-3">
      <slot name="before-export"></slot>
    </div>

    <div class="d-inline-flex justify-content-end ml-3">
      <b-dropdown
        v-if="!hideExport"
        variant="outline-primary"
        size="sm"
        class="small"
        right
        v-b-tooltip.hover.lefttop
        title="Export results to csv or pdf"
      >
        <template v-slot:button-content>
          <font-awesome-icon :icon="['fas', 'file-export']" />
        </template>
        <b-dropdown-item @click="exportToFile('all', 'csv')"
        >Export all as csv
        </b-dropdown-item>
        <b-dropdown-item
          :disabled="filteredRows.length == 0"
          @click="exportToFile('filtered', 'csv')"
        >Export filtered as csv
        </b-dropdown-item>
        <b-dropdown-item
          :disabled="selectedRows.length == 0"
          @click="exportToFile('selected', 'csv')"
        >Export selected as csv
        </b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item @click="exportToFile('all', 'pdf')"
        >Export all as pdf
        </b-dropdown-item>
        <b-dropdown-item
          :disabled="filteredRows.length == 0"
          @click="exportToFile('filtered', 'pdf')"
        >Export filtered as pdf
        </b-dropdown-item>
        <b-dropdown-item
          :disabled="selectedRows.length == 0"
          @click="exportToFile('selected', 'pdf')"
        >Export selected as pdf
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>


<script>
import bootstrapSort from '../scripts/bootstrap-sorting';
import exportToFile from '../scripts/csvexport';
import FilterItem from '../scripts/filterItem';
import { GetLocalStorageJSONWithDefault, SetLocalStorageJSON } from '../scripts/localstoragehelper';
import FilterEntry from './FilterEntry.vue';

export default {
  name: 'ReportTableHeader',
  components: {
    FilterEntry,
  },
  props: {
    reportId: {
      type: String,
    },
    title: {
      type: String,
    },
    headers: {
      type: Array,
    },
    rows: {
      type: Array,
    },
    table: {
      type: Object,
    },
    filterObjects: {
      type: Array,
    },
    filteredRows: {
      type: Array,
    },
    selectedRows: {
      type: Array,
    },
    hideColumnSelector: {
      type: Boolean,
      default: false,
    },
    hideExport: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      unused: '',
    };
  },
  computed: {
    /** Grab the columns that have already been marked as filterable */
    filterableHeaders() {
      return this.headers.filter(header => header.filterable);
    },

    /** Maps the filterable columns into a name/value pair */
    filterableSelection() {
      return this.filterableHeaders
        .map(header => ({
          value: header.key,
          text: header.label,
        }));
    },
  },

  methods: {

    /**
     * Filter for each row.  Check the filter definitions to find columns
     * that have a filter provided, and if so if the value in the row matches
     */
    filterRow(row) {
      const usedFilters = this.filterObjects
        .map(fo => fo.prepareForFiltering())
        .filter(fo => !!fo); // must not be null;

      // There are filters, only return the row if each filter has a matching value
      // in the row
      const rowMatches = usedFilters.every(fo => fo.matchesRow(row));
      return rowMatches;
    },

    addFilter() {
      this.filterObjects.push(new FilterItem());
      this.updateFilterValues();
    },

    removeFilter(index) {
      this.filterObjects.splice(index, 1);
      this.updateFilterValues();
    },

    updateFilterValues() {
      if (!this.reportId) return; // can't proceed if we have no report id

      // Build a list of filters, excluding the auto-added ones and the ones with no values
      const filtersToSave = this.filterObjects
        .filter(fo => !fo.autoAdded && fo.valueList && fo.valueList.length > 0)
        .map(fo => ({
          fieldName: fo.fieldName,
          valueList: fo.valueList,
        }));

      // Store the user's preference
      const storedFilters = GetLocalStorageJSONWithDefault('reportFilters', {});
      if (filtersToSave.length === 0) {
        delete storedFilters[this.reportId];
      } else {
        storedFilters[this.reportId] = filtersToSave;
      }
      SetLocalStorageJSON('reportFilters', storedFilters);
    },

    /**
     * Exports to csv or pdf.  May export all data, filtered data, or selected data.
     * Only visible columns are exported
     */
    // eslint-disable-next-line consistent-return
    exportToFile(itemsType, fileType) {
      // Start with the headers that are visible
      const headers = this.filterableHeaders.filter(header => header.displayed);

      // The rows to export will depend on the items type.
      let exportData;
      let exportDataFinal;
      if (itemsType === 'filtered') exportData = this.filteredRows;
      else if (itemsType === 'selected') exportData = this.selectedRows;
      else exportData = this.rows;

      // The table has already sorted the filtered items.
      // If the size of the table's sorted items is the same as our export
      // data that means we are exporting filtered items or all items
      // but we haven't filtered anything.  In this case, we can take the
      // already sorted items directly as the export.
      const { sortedItems } = this.table;
      const sortedItemLength = sortedItems.length;
      const exportDataLength = exportData.length;
      if (sortedItemLength === exportDataLength) {
        exportDataFinal = sortedItems;
      } else if (sortedItemLength > exportDataLength) {
        // if the number of sorted items is greater than the number of exported items
        // that means the user is exported selected items.  We can use the already
        // sorted list and just filter on the ones selected.

        exportDataFinal = sortedItems.filter(sortedItem => exportData.includes(sortedItem));
      } else {
        // Else, the number of sorted items is less than the number of
        // exported items, which happens when we export all.
        // In this case we have no choice but to sort all the records
        // because bootstrap won't provide the pre-sorted data
        exportDataFinal = bootstrapSort(exportData, this.table);
      }

      exportToFile(fileType, this.title, headers, 'label', 'key', exportDataFinal);
    },
  },

};
</script>
