
/**
 * Object definition for a Filter Item
 */
export default function FilterItem() {
  this.fieldName = null;
  this.valueList = null;
  this.autoAdded = false;

  /**
   * Returns true if this filter has a name and value
   */
  this.isUsed = function isUsed() {
    if (!this.fieldName) return false; // must have a name
    return this.valueList && this.valueList.length > 0;
  };

  /**
   * Returns null if there is no field name or value.
   * Otherwise updates the object for filtering by lowercaseing
   * and trimming the field name and value
   */
  this.prepareForFiltering = function prepareForFiltering() {
    if (!this.isUsed()) return null;
    this.fieldNamePrepped = this.fieldName.toLowerCase();
    this.valueListPrepped = this.valueList.map(v => v.toLowerCase().trim());
    return this;
  };

  /**
   * Returns true if the filter matches the row
   */
  this.matchesRow = function matchesRow(row) {
    if (!row[this.fieldNamePrepped]) return false; // The row must have a value
    const rowVal = row[this.fieldNamePrepped].toString().toLowerCase(); // make the value common
    return this.valueListPrepped.some(val => rowVal.includes(val));
  };

  /**
   * Adds a value for filter.  If we are not using tag filters then this becomes
   * the only value.  If using lists, append
   */
  this.addValue = function addValue(val) {
    this.value = val;
    if (!this.valueList) {
      this.valueList = [];
    }
    this.valueList.push(val);
  };
}
