import { render, staticRenderFns } from "./DeleteRows.vue?vue&type=template&id=4ff31f32"
import script from "./DeleteRows.vue?vue&type=script&lang=js"
export * from "./DeleteRows.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports