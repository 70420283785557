/** Copyright ACCEL 2020 */
/**
 * Keycloak initializers
 */
import * as Keycloak from 'keycloak-js';
import Vue from 'vue';


/**
   * Initializes session after successful authentication.
   * Takes the data resulting from the successful auth
   * @param {*} data
   */
function initializeSession(data, keycloak) {
  sessionStorage.setItem('name', data.name);

  // See if this person is an analyst.
  // NOTE!  This is not secure!  Only use this variable as a convenience
  // when we want to temporarily update the UI in certain cases,
  // NOT to determine real permissions.  That should only be done on the server end
  if (keycloak.realmAccess) {
    const { roles } = keycloak.realmAccess;
    if (roles) {
      const foundRole = roles.find(role => role === 'accel_analyst' || role === 'accel_admin_role' || role === 'admin');
      sessionStorage.isAnalyst = (foundRole !== undefined);
    }
  }

  // See if this person is a realm manager -- meaning they can view/create/update users in Keycloak
  // This is only used to determine if we should show menu items. The api server also checks in all
  // user admin functions for proper access.
  sessionStorage.removeItem('isRealmAdmin');
  if (keycloak.resourceAccess) {
    const realmMgmt = keycloak.resourceAccess['realm-management'];
    if (realmMgmt) {
      const { roles } = realmMgmt;
      if (roles) {
        const foundMgmtRole = roles.find(role => role === 'realm-admin' || role === 'manage-users' || role === 'query-users' || role === 'view-users');
        sessionStorage.isRealmAdmin = (foundMgmtRole !== undefined);
      }
    }
  }

  window.keycloak = keycloak; // need this for logout

  sessionStorage.setItem('vue-token', keycloak.token);
  sessionStorage.setItem('vue-refresh-token', keycloak.refreshToken);

  // Every 60 seconds refresh the jwt token if needed
  setInterval(() => {
    keycloak.updateToken(70).success((refreshed) => {
      if (refreshed) {
        sessionStorage.setItem('vue-token', keycloak.token);
        sessionStorage.setItem('vue-refresh-token', keycloak.refreshToken);
        // console.info(`Token refreshed${refreshed}`);
      } else {
        /*
          console.info(`Token not refreshed, valid for ${
            Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000)}
            seconds`);
          */
      }
    }).error(() => {
      Vue.$log.error('Failed to refresh token');
      // console.error('Failed to refresh token');
    });
  }, 60000);
}

/**
 * Class for setting up Keycloak in client apps
 */
export default class AccelKeycloak {
  keycloak = null;

  /**
   * Initializes the keycloak connection
   * @param {*} clientId The client id to pass to keycloak during init
   */
  constructor(clientId) {
    const initOptions = {
      // url: 'https://login2.accelcompliance.com/auth',
      url: process.env.VUE_APP_KEYCLOAK_URL,
      realm: process.env.VUE_APP_KEYCLOAK_REALM,
      clientId,
    };

    this.keycloak = Keycloak(initOptions);
  }


  /**
   * Intializes keycloak and, if authentication is successful, calls the startAppFunction provided
   * @param {*} startAppFunction
   */
  initAndLaunch(startAppFunction) {
    this.keycloak.init({ onLoad: 'login-required', enableLogging: true }).success((auth) => {
      if (!auth) {
        window.location.reload();
      } else {
        // Vue.$log.info("Authenticated");
      }

      this.keycloak.loadUserInfo().success((data) => {
        initializeSession(data, this.keycloak);
        startAppFunction();
      });
    }).error(() => {
      Vue.$log.error('Authenticated Failed');
    });
  }
}
