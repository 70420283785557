<!-- Copyright ACCEL 2019 -->
<!--
  Template class for re-usable paging for report table.
  Simple paging navigation with dropdown for the number of items per page
-->
<template>
  <div class="d-flex mb-3">
    <div class="d-inline-flex justify-content-start align-items-baseline ">
      <b-pagination
        v-model="currentPage"
        align="left"
        :total-rows="rowCount"
        :per-page="perPage"
        aria-controls="report_table"
        size="sm"
        @input="$emit('change-page', parseInt($event))"
      ></b-pagination>

      <b-form-select v-model="perPage" id="perPageSelect" variant="primary" size="sm"
        @input="$emit('change-perpage', parseInt($event))">
        <b-form-select-option value="25">25 per page</b-form-select-option>
        <b-form-select-option value="50">50 per page</b-form-select-option>
        <b-form-select-option value="100">100 per page</b-form-select-option>
      </b-form-select>
      <div class="small text-nowrap ml-3">{{computedTotalCountLabel}}</div>
    </div>
    <div class="d-inline-flex flex-grow-1">
      &nbsp;
      <!-- spacer -->
    </div>

    <!-- optionally allow users to put extra content on the right side -->
    <div class="d-inline-flex justify-content-end ml-3">
      <slot name="after-paging"></slot>
    </div>
  </div>
</template>


<script>
export default {
  name: 'ReportPaging',
  props: {
    initialPage: {
      type: Number,
    },
    rowCount: {
      type: Number,
    },
    initialPerPage: {
      type: Number,
    },
  },

  data() {
    return {
      currentPage: this.initialPage,
      perPage: this.initialPerPage,
    };
  },

  computed: {
    computedTotalCountLabel() {
      return `${this.rowCount} matching records`;
    },
  },

};
</script>
