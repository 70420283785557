 <!--
Copyright 2020 ACCEL Compliance

Management screen for onboarding new clients
-->
<template>
  <div>
    <div class="mb-4">
      <!--
      <h4>Welcome to the ACCEL Compliance Onboarding System</h4>
      -->
      <h4>Producers being onboarded for {{companyName}}</h4>
      For detailed instructions
      on how to use this system,
      <b-link v-b-toggle href="#collapse-instructions" @click.prevent>
        click here <font-awesome-icon :icon="['fas', 'info-circle']" />
      </b-link>

      <b-collapse id="collapse-instructions">
        <b-card title="Onboarding Instructions">
          <ol>
          <li>Individual producers can be added manually using the <strong>ADD</strong>
            button.  Producer records can also be edited using the <strong>EDIT</strong>
            button.<p/>
            <strong>To import multiple producers automatically, continue to step 2…
            </strong><p/>
          </li>
          <li>Download the
          <b-link href="/onboardingtemplate.csv">
            Producer Onboarding Template (csv file
            <font-awesome-icon :icon="['fas', 'file-csv']" />)
          </b-link>.<p/></li>
          <li>Open the Producer Onboarding Template in your preferred spreadsheet
            application (e.g., Excel).<p/></li>
          <li>Enter producer details to the spreadsheet, providing all required information.
              You can look up your producers' NPNs at
              <b-link href="https://nipr.com/licensing-center" target="_blank">
              https://nipr.com/licensing-center</b-link>. (Opens a new browser window.)<p/>
              Once on the NIPR Licensing Center webpage, scroll to the bottom to use the
              widget "Look Up Your National Producer Number (NPN)".<p/></li>
          <li>Once you have completed entering producer information in the spreadsheet,
            <strong>SAVE the file to your computer in csv format</strong>. (Note: The system
            will reject entries with duplicate or missing NPN numbers.)<p/></li>
          <li>Back in the ACCEL system (below), click on the <strong>IMPORT PRODUCERS</strong>
            button. Browse your computer to find the newly saved Producer Onboarding csv file
            and click <strong>IMPORT PRODUCERS</strong>.<p/></li>
          <li>After reviewing the Producer details entered for accuracy and completeness, click
            the <strong>SUBMIT</strong> button at bottom of screen to enable onboarding
            to begin.</li>
          </ol>
          If you have questions, please
            <b-link href="mailto:analysts@accelcompliance.com"
                    class="card-link">contact ACCEL</b-link> for assistance.
        </b-card>
      </b-collapse>
    </div>

    <b-alert :show="showImportErrors" variant="danger" dismissible>
      {{ importErrors }}
    </b-alert>

    <b-alert v-model="showImportCount" variant="success" dismissible>
      Successfully imported {{ importCount }} records.
    </b-alert>

    <b-alert :show="showImportWarnings" variant="warning" dismissible>
      Some records could not be imported from the csv file because of the following errors:
      <ul>
        <li v-for="importWarn in importWarnings" :key="importWarn" class="py-1">
          {{importWarn}}
        </li>
      </ul>
    </b-alert>

    <b-overlay
      :show="!showTable"
      rounded="sm"
      opacity="0.95"
      variant="white"
      spinner-variant="info"
      >

    <report-table
      :id="tableReferenceName"
      :ref="tableReferenceName"
      title="ACCEL Client Onboarding"
      :hideTitleRow="true"
      show-details
      selectable
      customSlotName1="cell_phone"
      :report-url="reportUrlBuilder"
      @report-retrieved="handleReportRetrievedChain"
      empty-filtered-text="Add a row or import producers from a file"
    >

      <!-- A custom formatted header cell for header 'deleting selected' -->
      <template v-slot:show-details-header="{selectedRows}">
        <delete-rows
          :selectedRows="selectedRows"
          :tableReference="$refs[tableReferenceName]"
          :deleteEndpoint="ormEndpoint"
          objectType="producer"
          objectTypePlural="producers"
        />
      </template>

      <!-- Put "Submit" in the optional slot after paging -->
      <template v-slot:after-paging-content>

        <div class="small text-center align-middle text-nowrap">
          <b-button
            v-b-modal.submit-confirmation
            size="sm"
            id="btn-submit"
            class="mr-1 small"
            variant="primary"
            :disabled="!haveRows"
            v-b-tooltip.hover.lefttop
            title="Submit the Producer list"
            >
              <span class="font-weight-normal ml-1">Submit</span>
          </b-button>


          <!-- modal dialog to confirm submitting -->
          <b-modal
            id="submit-confirmation"
            centered
            no-close-on-backdrop
            :title="'Confirm Producer List Submission'"
            header-bg-variant="secondary"
            header-text-variant="light"
            button-size="sm"
            @ok="submitOnboarding"
            >
            <p class="text-left mb-1">
              Submit this list of producers?  You will not be able to alter this
              list once it is submitted and it will no longer be available for viewing.
            </p>
          </b-modal>
        </div>
      </template>


      <!-- Put "Add Row" and "upload" in the option slot before export -->
      <template v-slot:before-export-content>
        <div class="small text-center align-middle text-nowrap">

          <!-- Put "Upload" in the option slot before export -->
          <b-button
            v-b-modal.modal-upload
            size="sm"
            class="small mr-2"
            variant="info"
            v-b-tooltip.hover.lefttop
            title="Import producers"
            >
            <font-awesome-icon :icon="['fa', 'file-upload']" />
              <span class="ml-3">Import Producers</span>
          </b-button>

          <!-- modal dialog to confirm changing status -->
          <b-modal
            id="modal-upload"
            centered
            size="xl"
            no-close-on-backdrop
            title="Producer Upload"
            header-bg-variant="secondary"
            header-text-variant="light"
            button-size="sm"
            ok-title="Import Producers"
            :ok-disabled="fileUploadDisabled"
            @show="openFileUploadModal()"
            @ok="handleFileUploadOk"
            @hide="handleUploadHide"
            >
              <b-container class="text-left mb-1">
                <b-row align-h="start" class="mb-4">
                  <b-col cols="12"><p/>
                    Browse your computer to find the Producer Onboarding csv file you saved.
                    The file format must match the Producer Onboarding template available for
                    download in the instructions.
                    <p/>
                    The National Producer Numbers (NPN) must be unique across all producers
                    in the upload.
                  </b-col>
                </b-row>
                <b-form-row  align-v="center" align-h="start"
                  class="mt-2">
                  <b-col cols="2">File to upload: </b-col>
                  <b-col cols="4">

                    <!--
                      use "no-drop" for now because drag/drop bypasses the setting for
                      not allowing multiple files/directories, and the accept type.
                      Basically, you can drag anything in.  We'd need further javascript
                      validation to restrict this
                      -->
                    <b-form-file
                      size="sm"
                      accept=".csv"
                      v-model="fileToUpload"
                      placeholder="Choose a file..."
                      drop-placeholder="Drop file here..."
                    >
                    </b-form-file>
                  </b-col>
                  <b-col>&nbsp;</b-col> <!-- force dropdown left -->
                </b-form-row>
                <b-form-row align-h="start" class="mt-4" v-if="fileCurrentlyUploading">
                  <b-col cols="3">Upload status: </b-col>
                  <b-col>
                    <b-progress
                      variant="success"
                      :value="fileUploadPercentage"
                      animated
                    ></b-progress>
                  </b-col>
                </b-form-row>
              </b-container>
          </b-modal>


          <!-- Put "Add Row" in the option slot before export -->
          <b-button
            size="sm"
            class="small"
            variant="info"
            v-b-tooltip.hover.lefttop
            title="Add a new producer"
            :disabled="currentlyAdding"
            @click="insertRow($refs[tableReferenceName])"
            >
            <font-awesome-icon :icon="['fas', 'plus-square']" />
            <span class="ml-1">Add</span>
          </b-button>

        </div>
      </template>

      <!-- scoped slot for show details button -->
      <template v-slot:show-details-cell="{row}">

        <!-- edit button -->
        <b-button
          size="sm"
          :id="'btn-edit-' + row.index"
          @click="toggleRowDetailsEdit(row, $refs[tableReferenceName])"
          class="mr-1 small"
          variant="primary"
          v-b-tooltip.hover.lefttop
          title="Edit the information for this Producer"
          >
            <font-awesome-icon :icon="['fas', 'edit']" />
            <span class="small font-weight-normal ml-1">Edit</span>
        </b-button>

      </template>

      <!-- Provide properly masked phone number for the phone -->
      <template v-slot:custom-cell(cell_phone)="{row}">
        <span>
        {{ formatPhone(row.item.cell_phone) }}
        </span>
      </template>

      <!-- scoped slot for rendering of actual details button -->
      <template v-slot:show-details-row="{row}">
        <b-container fluid class="text-left mt-4 pl-5 w-100">

          <edit-field-row
            :validateFunc="validate"
            :row="row"
            :cols="2"
            field1Label="Name: "
            field1Name="name"
            field1Feedback="A name is required"
            field2Label="NPN: "
            field2Name="npn_number"
            field2Feedback="A unique npn is required"
          />

          <edit-field-row
            :validateFunc="validate"
            :row="row"
            :cols="2"
            field1Label="Email: "
            field1Name="email"
            field1Feedback="A valid email address is required"
            field2Label="Mobile Phone: "
            field2Name="cell_phone"
            field2Mask="(###) ###-#### A#####"
            field2Feedback="A mobile phone number is required"
          />

          <edit-field-row
            :validateFunc="validate"
            :row="row"
            :cols="2"
            field2Label="Employee Id: "
            field2Name="employee_id"
          />


          <edit-buttons
            :row="row"
            @cancel-btn-click="cancelRowEdit(row, $refs[tableReferenceName])"
            @save-btn-click="updateRow(row)"
          />
        </b-container>
      </template>
    </report-table>
    </b-overlay>

  </div>
</template>

<style scoped>
.auto-changed-text {
  font-style: italic;
  color: #999999 !important;
}
</style>

<script>
import { parse as csvParse } from 'csv-parse/lib/sync';
import AddRow from '../vue-reusable-components/mixins/AddRow';
import EditableRows from '../vue-reusable-components/mixins/EditableRows';
import ReportRetrievedHandlerChain from '../vue-reusable-components/mixins/ReportRetrievedHandlerChain';
import DeleteRows from '../vue-reusable-components/components/tablehelpers/DeleteRows.vue';
import EditButtons from '../vue-reusable-components/components/tablehelpers/EditButtons.vue';
import EditFieldRow from '../vue-reusable-components/components/tablehelpers/EditFieldRow.vue';
import axiosClient from '../vue-reusable-components/scripts/axiosclient';
import ReportTable from '../vue-reusable-components/components/ReportTable.vue';
import { ValidateEmail } from '../vue-reusable-components/scripts/emailvalidator';
import FormatPhone from '../vue-reusable-components/scripts/phonemasker';
import updateFieldsPostSave from '../vue-reusable-components/scripts/setpostsavefields';

export default {
  mixins: [AddRow, EditableRows, ReportRetrievedHandlerChain],

  components: {
    ReportTable, DeleteRows, EditButtons, EditFieldRow,
  },

  data() {
    return {
      // The reference name for the table
      tableReferenceName: 'onboarding_table',

      fileToUpload: null,
      fileCurrentlyUploading: false,
      fileUploadPercentage: 0,


      // For the editable rows mixin
      requiredFields: ['name', 'email', 'npn_number', 'cell_phone'],
      fieldValidations: {
        email: [this.validateEmail],
        npn_number: [this.validateNPN],
      },

      baseReportUrl: 'services/report?view=onboardingProducersList',

      // For DeleteRow mix
      ormEndpoint: 'services/orm/onboarding_producers',

      importErrors: null,
      importWarnings: [],
      importCount: 0,
      showImportCount: false,

      onboardingId: null,
      companyName: '',
      currentlySubmitting: false,
    };
  },

  computed: {
    /**
     * Only show the table if the user has picked at a particular onboarding
     */
    showTable() {
      if (this.onboardingId && !this.currentlySubmitting) return true;
      return false;
    },

    /**
     * The endpoint to call when submitting
     */
    submitEndpoint() {
      if (this.onboardingId) return `services/onboarding/${this.onboardingId}/submit`;
      return '';
    },


    /**
     * Builds a url for the report with the base url, adding the onboarding id
     */
    reportUrlBuilder() {
      if (!this.showTable) {
        return null;
      }
      return `${this.baseReportUrl}&onboarding_id=${this.onboardingId}`;
    },

    /**
     * Returns true if import errors exist
     */
    showImportErrors() {
      return this.importErrors && this.importErrors !== '';
    },

    /**
     * Returns true if import warnings exist
     */
    showImportWarnings() {
      return this.importWarnings && this.importWarnings.length > 0;
    },

    /**
     * Returns true if the file upload OK button should be disabled because
     * we don't have all the required inputs, or if we are currently uploading
     */
    fileUploadDisabled() {
      return (this.fileCurrentlyUploading || !this.fileToUpload);
    },

    /**
     * Returns true if we have rows, false otherwise
     */
    haveRows() {
      return this.$refs[this.tableReferenceName].rows
        && this.$refs[this.tableReferenceName].rows.length > 0;
    },

  },

  methods: {

    /**
     * Validates that the email provided is valid
     */
    validateEmail(emailAddress) {
      // If there is no email address, return true
      // Required field validation happens first
      if (!emailAddress) return true;

      return ValidateEmail(emailAddress);
    },

    /**
     * Validates that the npn is unique
     */
    validateNPN(npn, theItem) {
      // If there is no npn, return true
      // Required field validation happens first
      if (!npn) return true;

      // Is npn unique?
      const dup = this.$refs[this.tableReferenceName].rows.find(
        r => r.npn_number === npn && r.id !== theItem.id,
      );
      if (dup) {
        return false;
      }
      return true;
    },

    /**
     * Provides masking for phone numbers so that we show it with the proper formatting.
     * This is ONLY used for the table cells -- not in editing.
     */
    formatPhone(phone) {
      return FormatPhone(phone);
    },


    /**
     * Submits the onboarding
     */
    submitOnboarding() {
      const vueInstance = this;
      this.currentlySubmitting = true;

      // Create a config object for axios so we can reuse a lot of code
      const config = {
        method: 'post',
        url: this.submitEndpoint,
      };

      axiosClient
        .request(config)
        // eslint-disable-next-line no-unused-vars
        .then((res) => {
          // Must refresh the summary data
          vueInstance.$emit('onboarding-submitted');

          vueInstance.$router.push('/'); // return to the dashboard
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        })
        .finally(() => {
          this.currentlySubmitting = false;
        });
    },

    /**
     * Updates the comments on a given row
     */
    updateRow(row, fromCsv) {
      const vueInstance = this;
      this.currentlyAdding = false;
      const { item } = row;

      if (!this.validForSave(item, true)) return;

      const { name } = item.tempData;
      const { email } = item.tempData;
      const cellPhone = item.tempData.cell_phone;
      const npnNumber = item.tempData.npn_number;
      const employeeId = item.tempData.employee_id;
      const { onboardingId } = this;

      // These fields always get set
      const json = {
        name,
        email,
        cellPhone,
        npnNumber,
        employeeId,
      };

      // If doing a create, add extra field that can't be edited
      if (item.id < 0) {
        json.onboardingId = onboardingId;
      }

      // Create a config object for axios so we can reuse a lot of code
      const config = {
        method: item.id > 0 ? 'put' : 'post',
        url: `${this.ormEndpoint}${item.id > 0 ? `/${item.id}` : ''}`,
        data: json,
      };

      axiosClient
        .request(config)
        // eslint-disable-next-line no-unused-vars
        .then((res) => {
          vueInstance.copyTempData(item);
          updateFieldsPostSave(item, res);
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        })
        .finally(() => {
          if (!fromCsv) {
            // Close row in finally so that temp data is appropriately set first
            vueInstance.toggleRowDetailsEdit(row, this.$refs[this.tableReferenceName], true);
          }
        });
    },


    /**
     * Prepares the modal when opening for upload
     */
    openFileUploadModal() {
      this.fileToUpload = null;
      this.fileUploadPercentage = 0;
      this.fileCurrentlyUploading = false;
      this.importErrors = null;
      this.importWarnings = [];
      this.importCount = 0;
      this.showImportCount = false;
    },

    /**
     * When the user clicks okay to upload file.  Prevent modal close
     */
    handleFileUploadOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      this.uploadFile(bvModalEvt.componentId);
    },

    /**
     * When the user tries to close the dialog.  Don't allow if we
     * are currently uploading, the user must click cancel button
     */
    handleUploadHide(bvModalEvt) {
      if (this.fileCurrentlyUploading) {
        // Prevent modal from closing
        bvModalEvt.preventDefault();
      }
    },

    /**
     * Method for closing the file modal and resetting the state.
     * If errors are provided sets the corresponding field
     */
    closeUploadFileModal(modalId, errors, importCount) {
      this.fileCurrentlyUploading = false;
      this.$bvModal.hide(modalId);
      this.importCount = importCount;

      if (errors) {
        this.importErrors = errors;
      } else if (importCount && importCount > 0) {
        this.showImportCount = true;
      }
    },

    /**
     * Gets a value from the row and makes sure it's defined (not null)
     * and the value is trimmed of leading and trailing whitespace
     */
    getSafeRowValueWithTrim(row, index) {
      let val = row[index];
      if (!val) {
        val = '';
      }
      val = val.trim();
      return val;
    },

    /**
     * Checks to see if the given row is valid.  If it's not
     * it will add to the list warning list.
     * Returns true if the row is valid and can be added
     */
    validateAndFormat(row, rowNumber) {
      const theRow = row;
      const name = this.getSafeRowValueWithTrim(row, 0);
      const email = this.getSafeRowValueWithTrim(row, 1);
      const npn = this.getSafeRowValueWithTrim(row, 2);
      const phone = FormatPhone(this.getSafeRowValueWithTrim(row, 3));
      const employeeId = this.getSafeRowValueWithTrim(row, 4);

      let allValid = true;
      if (name.length === 0) {
        this.importWarnings.push(`CSV file row #${rowNumber} is missing a producer name`);
        allValid = false;
      }
      if (email.length === 0 || !ValidateEmail(email)) {
        this.importWarnings.push(`CSV file row #${rowNumber} does not contain a valid email address`);
        allValid = false;
      }
      if (npn.length === 0) {
        this.importWarnings.push(`CSV file row #${rowNumber} is missing a national producer number`);
        allValid = false;
      }
      if (phone.length === 0) {
        this.importWarnings.push(`CSV file row #${rowNumber} is missing a phone number`);
        allValid = false;
      }

      // Is npn unique?
      const dup = this.$refs[this.tableReferenceName].rows.find(r => r.npn_number === npn);
      if (dup) {
        this.importWarnings.push(`CSV file row #${rowNumber} contains a duplicate npn (${npn})`);
        allValid = false;
      }

      // Values have been trimmed and maybe formatted, so set back into the data
      theRow[0] = name;
      theRow[1] = email;
      theRow[2] = npn;
      theRow[3] = FormatPhone(phone);
      theRow[4] = employeeId;

      return allValid;
    },

    /**
     * Uploads file to the server
     */
    uploadFile(modalId) {
      // debugger
      const vue = this;
      this.fileCurrentlyUploading = true;

      const reader = new FileReader();
      reader.onload = (res) => {
        const content = res.target.result;
        let records;
        try {
          records = csvParse(content,
            {
              skip_empty_lines: true,
              trime: true,
            });
        } catch (e) {
          this.closeUploadFileModal(modalId, `The csv file could not be parsed because of an error: ${e}`);
          return;
        }

        // error check the import
        if ((!records) || (records.length === 0)) {
          this.closeUploadFileModal(modalId, 'The file contains no data');
          return;
        }

        const firstRow = records[0];
        if (firstRow.length !== 5) {
          this.closeUploadFileModal(modalId, 'The file format is invalid');
          return;
        }

        let nonHeadersRecordsExist = false;
        let rowNum = 0;
        let importCount = 0;
        records.forEach((record) => {
          rowNum += 1;
          const firstCell = record[0];

          // ignore header columns
          if ((firstCell === 'Name') || firstCell.startsWith('For each Individual')) {
            return;
          }
          nonHeadersRecordsExist = true;

          // validate
          if (!this.validateAndFormat(record, rowNum)) {
            return;
          }

          // otherwise create a new row item
          const newData = {
            name: record[0],
            email: record[1],
            npn_number: record[2],
            cell_phone: record[3],
            employee_id: record[4],
          };
          const newRowItem = this.insertData(this.$refs[this.tableReferenceName], newData);

          // save the row to the server
          this.updateRow({ item: newRowItem }, true);


          importCount += 1;
        });

        if (!nonHeadersRecordsExist) {
          this.closeUploadFileModal(modalId, 'The file contains no data');
        }
        this.closeUploadFileModal(modalId, null, importCount);
      };
      // update the progress bar on large imports
      reader.onprogress = (progressEvent) => {
        vue.fileUploadPercentage = parseInt(
          Math.round((progressEvent.loaded / progressEvent.total) * 100), 10,
        );
      };
      reader.onerror = (err) => {
        this.closeUploadFileModal(modalId, `The csv file could not be loaded because of the following error: ${err}`);
      };

      reader.readAsText(this.fileToUpload);
    },

    /**
     * Looks to see if we received an onboarding id in the request url
     */
    setNavValues(route) {
      let tempParams = route.params;
      if (route.params == null) {
        tempParams = this.$route.params; // this happens when we use browser refresh
        if (tempParams == null) tempParams = {}; // Just to avoid null checks
      }

      this.companyName = route.query.cn;

      // The report will refresh based on url change
      if (tempParams.onboardingId) {
        this.onboardingId = tempParams.onboardingId;
      } else {
        this.onboardingId = null;
      }
    },


  },

  created() {
    this.addReportRetrievedHandler(this.handleReportRetrievedAddRow);
    this.addReportRetrievedHandler(this.handleReportRetrievedEditableRow);
  },
  mounted() {
    this.setNavValues(this.$route);
  },

  watch: {
    $route(to) {
      this.setNavValues(to);
    },
  },

};
</script>
