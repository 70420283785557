<!--
Copyright 2020 ACCEL Compliance

Main layout for the application, it has links for the navigation header
and the footer.  The main body is set using the router-view.
-->
<template>
  <div class="layout">
    <header class="layout-header">
      <Navbar
        :activeSection="activeSection"
        :activeSubSection="activeSubSection"
      ></Navbar>
    </header>
    <b-container fluid class="px-4 mt-3 main-content">
      <b-row>
        <b-col>
          <router-view @onboarding-submitted="$emit('onboarding-submitted')" />
        </b-col>
      </b-row>
    </b-container>

    <footer class="layout-header">
      <Footer />
    </footer>
  </div>
</template>

<!-- The styles below are used to create a footer that stays at the bottom -->
<style scoped>
.layout {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100vh; /* 1 */
}
.main-content {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto; /* 2 */
}

.layout-header,
.layout-footer {
  -ms-flex-negative: 0;
  flex-shrink: 0; /* 2 */
}
</style>

<script>
import Navbar from './Navbar.vue';
import Footer from './Footer.vue';

export default {
  name: 'Layout',
  components: {
    Footer,
    Navbar,
  },
  props: {
    // The name of the active navigation section
    // Only used if manually set on the component instead
    // of determining from the url
    activeSectionOverride: {
      type: String,
    },

    // The name of the active navigation sub-section, if any
    // Only used if manually set on the component instead
    // of determining from the url
    activeSubSectionOverride: {
      type: String,
    },
  },

  data() {
    return {
      // The name of the active navigation section
      activeSection: null,

      // The name of the active navigation sub-section, if any
      activeSubSection: null,
    };
  },

  methods: {
    /**
     * Sets values needed when navigation updates but the component lifecycle has not changed
     */
    setNavValues(params) {
      let tempParams = params;
      if (params == null) {
        tempParams = this.$route.params; // this happens when we use browser refresh
        if (tempParams == null) tempParams = {}; // Just to avoid null checks
      }
      this.activeSection = tempParams.activeSection
        ? tempParams.activeSection
        : this.activeSectionOverride;

      this.activeSubSection = tempParams.activeSubSection
        ? tempParams.activeSubSection
        : this.activeSubSectionOverride;
    },
  },
  mounted() {
    this.setNavValues(this.$route.params);
  },
  watch: {
    /**
     * Watch for changes to the route
     */
    $route(to) {
      this.setNavValues(to.params);
    },
  },
};
</script>
