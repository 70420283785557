/* eslint-disable import/prefer-default-export */

/**
 Copyright 2022 ACCEL Compliance
 Helper functions for working with local storage
*/

import { del, get, set } from 'idb-keyval';

/**
 * Tries to get the item from the using the provided key
 * and if not available returns the default value.  The value
 * is expected to be JSON, and the result of JSON.parse is returned.
 * This is an async function!  It will return a promise and .then() must be
 * used to get the value.
 * @param {string} storeName
 * @param {Object} defaultVal
 */
export async function GetStorageJSONWithDefault(keyName, defaultVal) {
  const dbVal = await get(keyName);
  if (dbVal) {
    const json = JSON.parse(dbVal);
    return json;
  }
  return defaultVal;
}

/**
 * Takes the input object, stringify's it to a JSON string, and
 * saves the value to localstorage.  If the input object is null
 * then the local storage key is removed.
 * @param {string} keyName
 * @param {Object} val
 */
export function SetStorageJSON(keyName, val) {
  if (!val) {
    del(keyName);
  } else {
    set(keyName, JSON.stringify(val));
  }
}

/**
 * Tries to get the item from local storage using the provided key
 * and if not available returns the default value.  The value
 * is expected to be JSON, and the result of JSON.parse is returned.
 * @param {string} keyName
 * @param {Object} defaultVal
 */
export function GetLocalStorageJSONWithDefault(keyName, defaultVal) {
  const val = localStorage.getItem(keyName);
  if (val) {
    const json = JSON.parse(val);
    return json;
  }
  return defaultVal;
}

/**
 * Takes the input object, stringify's it to a JSON string, and
 * saves the value to localstorage.  If the input object is null
 * then the local storage key is removed.
 * @param {string} keyName
 * @param {Object} val
 */
export function SetLocalStorageJSON(keyName, val) {
  if (!val) {
    localStorage.removeItem(keyName);
  } else {
    localStorage.setItem(keyName, JSON.stringify(val));
  }
}
