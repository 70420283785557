<!--
Copyright 2020 ACCEL Compliance

Helper component for displaying the save/cancel/update buttons in the
details section of the editable row
-->

<template>

  <b-row class="mt-4">
    <b-col :cols="cols" align-self="center" class="text-right">
        <b-button size="sm" class="btn m-2"
          @click="$emit('cancel-btn-click')"
        >Cancel</b-button>
        <b-button
          v-if="showSaveUpdate"
          size="sm"
          class="btn m-2"
          variant="primary"
          @click="$emit('save-btn-click')"
        >
        <span v-if="IsExistingRecord(row.item.id)">Update</span>
        <span v-else>Save</span>
        </b-button>
    </b-col>
    <b-col sm="auto" :cols="remainingCols">&nbsp;</b-col>
  </b-row>


</template>

<script>
import IsExistingRecord from '../../scripts/utils';

export default {
  name: 'EditButtons',

  props: {
    row: {
      type: Object,
    },
    cols: {
      type: Number,
      default: 11,
    },
    disableUpdate: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    remainingCols() {
      return 12 - this.cols;
    },

    /**
     * Whether or not show the update button.
     * If id is less than equalt to0, returns true because this is a save button.
     * If id is greater than 0, return true if "disableUpdate" flag is not set
     */
    showSaveUpdate() {
      if (this.row.item.id <= 0) {
        return true;
      }
      return !this.disableUpdate;
    },
  },

  methods: {
    IsExistingRecord,
  },

};
</script>
