/**
Copyright 2020 ACCEL Compliance

Re-usable mixin for adding an item to a table.

Defines:
 * currentlyAdding - data field to indicate that we are currently adding a row.  Only
                    one add at a time should be allowed.
 * cancelRowEdit - Cancels a row edit, toggling the details as normal but also deleting
                    the record if the id is less than 0
 * insertRow - Adds a new data row.  A default new row is constructed using
                    the fields returned from the report
 * filterOverride - Makes sure rows with negative id are not filtered out
 * handleReportRetrievedAddRow - Can be chained, determines a default new row object

*/

import IsExistingRecord from '../scripts/utils';

export default {


  data() {
    return {
      currentlyAdding: false,
      modifyDefaultNewRow: null, // function to modify the default new row
    };
  },

  methods: {
    IsExistingRecord,

    /**
     * Cancels row edit.  Normally just toggles details
     * but if this is a new row it will delete it
     */
    cancelRowEdit(row, referenceTable) {
      this.currentlyAdding = false;
      const rowId = row.item.id;

      if (this.IsExistingRecord(rowId)) this.toggleRowDetailsEdit(row, referenceTable, true);
      else referenceTable.removeRows('id', [rowId]);
    },

    /**
     * When loading the table, create a sample defaultNewRow based on the
     * headers that have come back from the report
     */
    handleReportRetrievedAddRow(json) {
      const { fields } = json;

      let newRow = {};

      fields.forEach((field) => {
        newRow[field.key] = null;
      });

      if (this.modifyDefaultNewRow) {
        newRow = this.modifyDefaultNewRow(newRow);
      }
      this.defaultNewRow = newRow;
    },

    /**
     * Inserts a data record into the table.
     * The row will get a random negative id (negative so we know
     * that it hasn't been saved to the db yet).
     * Sets up all the temp data copy so we can edit the row later.
     */
    insertData(referenceTable, data) {
      const newRow = Object.assign({}, data);
      newRow.id = (Math.floor(Math.random() * 1000000) * -1) - 1;

      // Set up temp data object. This is used to store the values
      // when we are editing the row so we can cancel edit
      const tempData = {};
      const inputTouched = {};
      Object.keys(newRow).forEach((key) => {
        tempData[key] = newRow[key];
        inputTouched[key] = false;
      });
      newRow.tempData = tempData;
      newRow.inputTouched = inputTouched;

      referenceTable.addRow(newRow);
      return newRow;
    },

    /**
     * Inserts a row into the table, but does NOT save it yet
     */
    insertRow(referenceTable) {
      this.currentlyAdding = true;

      const newRow = this.insertData(referenceTable, this.defaultNewRow);

      setTimeout(() => {
        document
          .getElementById('btn-edit-0')
          .dispatchEvent(new MouseEvent('click', { ctrlKey: false }));
      }, 100);

      return newRow;
    },

    /**
     * Checks the id of the item and if it's negative then do
     * not filter the row.  This lets new rows show despite filters.
     */
    filterOverride(row) {
      return row.id && row.id < 0;
    },

  },

};
