/** Copyright ACCEL 2020 */
/**
  Handler for global options in the navbar.
  Use localstorage to store a JSON object for options
 */

export default class UserOptions {
  defaults = {
    showNavbarCounts: true,
    myCompaniesOnly: null,
    licensingArea: null,
    overrideClientId: null,
    overrideCompanyId: null,
  };

  options = {};

  /**
   * Retrieve the options from localstorage if they exist
   */
  constructor() {
    Object.assign(this.options, this.defaults); // apply any missing defaults
    if (localStorage.optionsStore) {
      const tempOptions = JSON.parse(localStorage.optionsStore);
      Object.assign(this.options, tempOptions);
    }
  }

  /**
   * Retrieves a value from the options if it exists, otherwise null
   * @param {*} name
   */
  getOption(name) {
    return this.options[name];
  }

  /**
   * Sets an option into the options and saves is
   * @param {*} name
   * @param {*} value
   */
  setOption(name, value) {
    this.options[name] = value;
    localStorage.setItem('optionsStore', JSON.stringify(this.options));
  }
}
