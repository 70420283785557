/**
 Copyright 2021 ACCEL Compliance

 Input mask for phone numbers
 */
import { conformToMask } from 'text-mask-core';

require('text-mask-core');

const phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

export default function FormatPhone(phone) {
  if (!phone) return phone;
  const results = conformToMask(phone, phoneMask);
  return results.conformedValue;
}
