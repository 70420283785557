/*
Copyright 2020 ACCEL Compliance

Re-usable mixin for editing rows in a table.

Defines:
 * toggleRowDetailsEdit
 * handleReportRetrievedEditableRow (can be chained)

These copy all row data into a temp object that stores
the initial state and restores that state.

Also defines:
 * requiredFields - A list of field names that are required.  See below
 * validateState - Validates that a field has a value if it's one of the required fields
 *    and passes any extra validations in fieldValidations
 * validForSave
 * touchInput - mark a field as being touched by the user.  Set this
 *    on blur or another event when the user has lost focus from the field.
 * fieldValidations - An object mapping field names to list of validatation methods to
 *    call on that field.  The methods should accept the value of the field
 *    as the first parameter and the whole object as the second paramter - in case
 *    a method needs to compare multiple fields.
 *    All validation is done on the "tempData" fields, not the main fields.
 *    The validation methods are only called if the item has been touched and has
 *    passed the required validation (if applicable)
 * conditionalRequireds - An object mapping field name to function to call to determine whether
 *    the field should be required.  The function should take the field name and row.item object
 *    as inputs and return true if the field is required, false if not.  This is
 *    for fields that are only required under certain circumstances.
 * extraTempData - An object to be set by the user of the mixin, this contains extra
 *    fields that will be copied into temp data when editing.  This is used to set
 *    flags or other transient data that must appear in temp data before the row appears

These are  used to make the state red or green, keeping track of
which field have been touched.

To use this, define a data item named "requiredFields" that is an
array of field names (column names) from the report table corresponding
to the fields that are required.  On the form inputs, make sure to set

  @blur="row.item.inputTouched.your_field = true"

when focus is lost on fields that are required (@blur is just an example for
text boxes - calenders should use @hidden), and

  :state="validateState(row.item, 'your field')"

to use the validatation.

The "validForSave" method can be called to disable a save button or perform
a check before sending the save
*/

export default {

  data() {
    return {
      currentlyAdding: false,

      // If the component has required fields on the item being
      // edited they should override this array with the names
      // of the required fields
      requiredFields: [],

      // An object describing any extra validate methods to call during
      // "validForSave" and "validateState".  The object keys are the field
      // names and the values are validation functions.
      // These must be methods that take up to two parameters.  The first
      // parameter is the current value, the 2nd parameter is the row item
      fieldValidations: {},

      // An object containing the extra fields to add/set in temp data
      // when showing an item for editing.
      extraTempData: {},

      // An describing fields that are only sometimes required.  The object
      // keys are field names, and the values are functions that determine
      // whether that field is currently required based on the available data
      conditionalRequireds: {},
    };
  },


  methods: {

    /**
     * Marks a particular field as touched on the item, which allows the
     * state to show green or red
     */
    touchInput(item, field) {
      const theItem = item;
      theItem.inputTouched[field] = true;
    },

    /**
     * Merges the requiredFields list with the conditionalRequireds to return
     * a list of all the currently required fields.  Always returns at least
     * an empty array, so the caller doen't need to null check.
     */
    getAllRequiredFields(item) {
      // Copy the required fields into new array, or create a new array
      const currentlyRequired = (this.requiredFields ? [...this.requiredFields] : []);
      if (this.conditionalRequireds && Object.entries(this.conditionalRequireds)) {
        Object.entries(this.conditionalRequireds).forEach((entry) => {
          const [fieldName, func] = entry;

          // value should be a function taking the field name and row item
          const required = func(fieldName, item);
          if (required) {
            currentlyRequired.push(fieldName);
          }
        });
      }
      return currentlyRequired;
    },

    /**
     * Validates the given field from the item.
     * Returns null if the input is not touched.
     * If the value is touched it proceeds to check whether there is a value
     * if the field is one of the required fields.
     * If the the required validation is not applicable or passes then
     * any validation methods in the fieldValidations map are called.
     * If all validations pass then true is returned, otherwise false.
     *
     * All validation is done on the tempData object
     */
    validate(item, fieldName) {
      // Handle cases where the item has been unloaded
      if ((!item) || (!item.inputTouched)) return null;
      if (!item.inputTouched[fieldName]) return null;

      let valid = true;
      const value = item.tempData[fieldName];
      const allRequiredFields = this.getAllRequiredFields(item);
      if (allRequiredFields.includes(fieldName)) {
        valid = !!value;
      }

      if (!valid) return false;
      const moreValidations = this.fieldValidations[fieldName];
      const theItem = item;
      if (moreValidations) {
        valid = moreValidations.reduce(
          (allMatch, validationMethod) => allMatch && validationMethod(value, theItem), true,
        );
      }
      return valid;
    },

    /**
     * Returns true if all the required fields have been provided, false otherwise.
     * If the forceTouch parameter is set to true, then all inputs will be considered
     * touched and will trigger validation display
     */
    validForSave(item, forceTouch) {
      const theItem = item;
      if (forceTouch) {
        const allRequiredFields = this.getAllRequiredFields(item);
        allRequiredFields.forEach((field) => { theItem.inputTouched[field] = true; });
      }

      // reduce by calling validate on every field
      const allRequirementsMet = Object.keys(item).reduce(
        (allMatch, fieldName) => {
          // skip tempData and inputTouched special fields
          if (fieldName === 'tempData' || fieldName === 'inputTouched'
            || fieldName === '_showDetails') return allMatch;

          // call the validate method for the field
          let fieldValid = this.validate(theItem, fieldName);

          // This will return true (valid), false (not valid) or null (not touched)
          // Since all required field have been marked at touched, a null is considered
          // valid in this case
          if (fieldValid === null) fieldValid = true;

          return (allMatch && fieldValid);
        }, true,
      );
      return allRequirementsMet;
    },

    /**
     * Reset the editable when toggling details
     */
    toggleRowDetailsEdit(row, tableReference, doReset = false) {
      const theRow = row == null ? this.currentRow : row;
      const { item } = theRow;
      if (doReset) {
        // Set up temp data object. This is used to store the values
        // when we are editing the row so we can cancel edit
        const tempData = {};
        const inputTouched = {};
        Object.keys(item).forEach((key) => {
          tempData[key] = item[key];
          inputTouched[key] = false;
        });

        // Copy any additional temp data fields
        if (this.extraTempData) {
          Object.keys(this.extraTempData).forEach((key) => {
            tempData[key] = this.extraTempData[key];
          });
        }
        item.tempData = tempData;
        item.inputTouched = inputTouched;
      }
      return tableReference.toggleRowDetails(theRow);
    },

    /**
     * When loading the table, put the all the editable fields into
     * "temp" fields for editing
     */
    handleReportRetrievedEditableRow(json) {
      this.rows = json.rows;
      this.rows.forEach((row) => {
        const theRow = row;

        // Set up temp data object. This is used to store the values
        // when we are editing the row so we can cancel edit
        const tempData = {};
        const inputTouched = {};
        Object.keys(theRow).forEach((key) => {
          tempData[key] = theRow[key];
          inputTouched[key] = false;
        });
        theRow.tempData = tempData;
        theRow.inputTouched = inputTouched;
      });
    },

    /**
     * Used to copy fields from a response back to the item
     */
    copyTempData(item) {
      const theItem = item;
      Object.keys(item.tempData).forEach((key) => {
        if (key !== '_showDetails') { // special field used by boot
          theItem[key] = item.tempData[key];
        }
      });
    },

  },

};
