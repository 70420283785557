<!--
Copyright 2020 ACCEL Compliance

Generic page for reporting using the standard reporting
framework.  Needs the report url to render
-->
<template>
  <div>
        <report-table
          v-if="reportUrl != null"
          id="report_table"
          ref="report_table"
          :title="reportTitle"
          selectable
          :report-url="reportUrl"
          @report-retrieved="handleReportRetrieved"
        >

        </report-table>
  </div>
</template>

<script>
import ReportTable from '../vue-reusable-components/components/ReportTable.vue';

export default {
  name: 'ReportGeneric',
  components: {
    ReportTable,
  },
  props: {
    // The name of the active navigation sub-section, if any
    // Only used if manually set on the component instead
    // of determining from the url
    activeSubSectionOverride: {
      type: String,
    },
  },
  data() {
    return {
      reportTitle: '',
      report: null,
    };
  },
  computed: {
    reportUrl() {
      return `services/report?view=${this.report}`;
    },
  },
  methods: {
    setNavValues(params) {
      this.reportTitle = 'Loading report...';
      let tempParams = params;
      if (params == null) {
        tempParams = this.$route.params; // this happens when we use browser refresh
        if (tempParams == null) tempParams = {}; // Just to avoid null checks
      }
      this.report = tempParams.activeSubSection
        ? tempParams.activeSubSection
        : this.activeSubSectionOverride;
      // report will be refreshed with the watch on the url
    },

    /**
     * When loading the table, put the comments into "tempComment" for editing
     */
    handleReportRetrieved(json) {
      this.reportTitle = json.title;
    },
  },
  mounted() {
    this.setNavValues(this.$route.params);
  },
  watch: {
    $route(to) {
      this.setNavValues(to.params);
    },
  },
};
</script>
