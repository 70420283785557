// Code copied from bootstrap becausee the sort routines are not
// exported and available in our code

// Start with
// node_modules/bootstrap-vue/src/components/table/helpers/mixin-sorting.js
// and look at the sortedItems function.
// It will also have imports from other files

import { isFunction, isUndefinedOrNull } from 'bootstrap-vue/src/utils/inspect';
import { stableSort } from 'bootstrap-vue/src/utils/stable-sort';
import { defaultSortCompare } from 'bootstrap-vue/src/components/table/helpers/default-sort-compare';


/**
 * Below is a copy of the code from bootstrap, replacing
 * "this" with "table" and supplying the items directly
 * @param {*} items The items to sort
 * @param {*} table The table the items are in
 */
function boostrapSort(items, table) {
  const {
    localSortBy: sortBy,
    localSortDesc: sortDesc,
    sortCompareLocale: locale,
    sortNullLast: nullLast,
    sortCompare,
    localSorting,
  } = table;
  const localeOptions = { ...table.sortCompareOptions, usage: 'sort' };

  if (sortBy && localSorting) {
    const field = table.computedFieldsObj[sortBy] || {};
    const { sortByFormatted } = field;
    // eslint-disable-next-line no-nested-ternary
    const formatter = isFunction(sortByFormatted)
      ? /* istanbul ignore next */ sortByFormatted
      : sortByFormatted
        ? table.getFieldFormatter(sortBy)
        : undefined;

    // `stableSort` returns a new array, and leaves the original array intact
    return stableSort(items, (a, b) => {
      let result = null;
      // Call user provided `sortCompare` routine first
      if (isFunction(sortCompare)) {
        // TODO:
        //   Change the `sortCompare` signature to the one of `defaultSortCompare`
        //   with the next major version bump
        result = sortCompare(a, b, sortBy, sortDesc, formatter, localeOptions, locale);
      }
      // Fallback to built-in `defaultSortCompare` if `sortCompare`
      // is not defined or returns `null`/`false`
      if (isUndefinedOrNull(result) || result === false) {
        result = defaultSortCompare(a, b, {
          sortBy,
          formatter,
          locale,
          localeOptions,
          nullLast,
        });
      }
      // Negate result if sorting in descending order
      return (result || 0) * (sortDesc ? -1 : 1);
    });
  }

  return items;
}


export default boostrapSort;
